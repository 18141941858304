import { useEffect, useRef, useState } from "react";
import {
  CannabisDispensariesService,
  UserService,
} from "src/app/shared/services";
import { CardChangeDispensaries } from "../card-change-dispensaries";
import { Toast } from "primereact/toast";
// import useAuth from "src/app/shared/hooks/useAuth";
import "./dispensaries.css";
import { AppType } from "src/app/shared/hooks/AppType";

export const DispensariesList = () => {
  const appType = AppType();
  const toast = useRef(null);

  const [dispensaries, setDispensaries] = useState([]);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    const userService = new UserService();
    const cannabisDispensariesService = new CannabisDispensariesService();

    userService
      .getCurrentUser(appType)
      .then(([err, data]) => !err && setSelected(data?.prodReccDisp))
      .catch((err) => console.error(err));

    cannabisDispensariesService
      .search()
      .then(([err, res]) => {
        if (err) {
          setDispensaries([]);
        } else {
          const processedRes =
            process.env.REACT_APP_ENV === "production"
              ? res.filter((item) => !item.isTesting)
              : res;
          const smartCatalog = {
            name: "Smart Catalog",
            location: "Thcprecheck",
            imgUrl: "/imgs/thc-precheck-logo.png",
          };
          const updatedDispensaries = [smartCatalog, ...processedRes];
          setDispensaries(updatedDispensaries);
        }
      })
      .catch((_) => setDispensaries([]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Toast ref={toast} />
      <div className="container-list-dispensaries">
        <p>{dispensaries.length} places ordered by distance</p>
        <div>
          {dispensaries.length > 0 &&
            dispensaries?.map((disp, i) => (
              <CardChangeDispensaries
                data={disp}
                key={i}
                selected={selected}
                setSelected={setSelected}
                toast={toast}
              />
            ))}
        </div>
      </div>
    </>
  );
};
