import React from "react";
import "./banner-prod-recd.css";

export const BannerProdRecom = () => {
  return (
    <div className="banner-container">
      <div className="banner-container-info">
        <div className="banner-container-info-text">
          <p className="title-banner">PRODUCT</p>
          <p className="subtitle-banner">Recommendations</p>
        </div>
        <div className="banner-container-info-img">
          <img src="/icons/v2/planta-prod2.png" alt="planta-logo" />
        </div>
      </div>
    </div>
  );
};
