import client from "../utils/base-client.utils";
import authClient from "../utils/auth-cookie-client.utils";
import { decryptData } from "../helpers/encrypt";
import { dispensariesMock } from "./../../../data/dispensaries-mock";
export class CannabisDispensariesService {
  async search(payload) {
    try {
      const response = await client.get("/dispensaries", payload);
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async getInfo(id, appType) {
    try {
      const { data } = await authClient(appType).get(`/dispensaries/${id}`);
      // const dataDecrypt = decryptData(data?.payload);
      return [null, data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async update(payload, appType) {
    try {
      const response = await authClient(appType).put(`/dispensaries`, payload);
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async uploadLogoImage(formData, appType) {
    try {
      const response = await authClient(appType).post(
        `/dispensaries/upload-logo`,
        formData
      );

      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
}

export class CannabisDispensariesTestingService {
  async search(payload) {
    try {
      return [null, dispensariesMock];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
  async getInfo(id, appType) {
    try {
      const dispensary = dispensariesMock.find((d) => d._id === id);
      return [null, dispensary];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
}
