import { Card } from "primereact/card";
import { passport } from "src/app/constants/passport.constants";

export const AvoidTemplate = ({ listAvoid }) => {
  const style = {
    passportCard: {
      borderRadius: "12px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      fontFamily: '"Poppins", sans-serif',
      textAlign: "center",
      //OTHER
      height: "100%",
      alignItems: "center",
      marginLeft: "1rem",
    },
    titleCard: {
      color: "#f75353",
      fontSize: "1.1rem",
      fontWeight: 700,
    },
    itemAvoid: {
      background: "rgba(246, 155, 135, 0.5)",
      borderRadius: "12px",
      padding: "0 1rem",
      height: "22px",
      fontSize: "14px",
      fontWeight: "600",
      color: "#696c72",
      marginBottom: "0.3rem",
    },
  };

  return (
    <td style={{ width: "100%" }}>
      <Card
        style={style.passportCard}
        title={<span style={style.titleCard}>{passport.TITLE7}</span>}
      >
        <div style={{ padding: "0 0.5rem" }}>
          {listAvoid?.map((item, i) => (
            <div style={style.itemAvoid} key={i}>
              {item}
            </div>
          ))}
        </div>
      </Card>
    </td>
  );
};
