import { decryptData } from "../helpers/encrypt";
import cookieClient from "../utils/auth-cookie-client.utils";

export class UserService {
  async forceVerification(appType, payload) {
    try {
      const response = await cookieClient(appType).post(
        "/verify/create",
        payload
      );
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async getCurrentUser(appType) {
    try {
      const { data } = await cookieClient(appType).get("/user/token");
      const dataDecrypt = decryptData(data?.payload);
      return [null, dataDecrypt];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async updateCurrentUser(username, payload, appType) {
    try {
      const response = await cookieClient(appType).put(
        "/user/" + username,
        payload
      );
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async updateDispensaryPreferred(username, payload, appType) {
    try {
      const response = await cookieClient(appType).put(
        "/user/changedisp/" + username,
        payload
      );
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async updateUserPreference(username, payload, appType) {
    try {
      const response = await cookieClient(appType).put(
        `/user/${username}/cannabis-preferences`,
        payload
      );
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async uploadProfileImage(formData, appType) {
    try {
      const { data } = await cookieClient(appType).post(
        `/user/profile-image`,
        formData
      );
      const dataDecrypt = decryptData(data?.payload);
      return [null, dataDecrypt];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
}
