import React from "react";
import { passport } from "src/app/constants/passport.constants";

export const HeaderTemplate = ({ level, user = {} }) => {
  const style = {
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      fontFamily: '"Poppins", sans-serif',
    },
    headerContent: {
      display: "flex",
    },
    title: {
      color: "#faa21a",
      margin: "0",
      fontSize: "28px",
      textAlign: "end",
    },
    headerImg: {
      display: "flex",
      alignItems: "center",
    },
    headerAvatarImg: {
      width: "75px",
      height: "75px",
      border: "3px solid #36b37e",
      borderRadius: "50%",
    },
    infoUser: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "1rem",
      textAlign: "start",
      width: "85%",
    },
    infoUserH2: {
      fontSize: "1.8rem",
      margin: "0",
      color: "#183b56",
      alignItems: "start",
    },
    infoUserH3: {
      fontSize: "12px",
      margin: 0,
      textAlign: "start",
      color: "#36b37e",
    },
    headerType: {
      background: "rgba(135, 233, 246, 0.5)",
      borderRadius: "12px",
      width: "max-content",
      padding: "0 1rem",
      height: "22px",
      fontSize: "16px",
      fontWeight: "600",
      color: "#696c72",
      textAlign: "center",
    },
  };

  return (
    <tr>
      <td
        style={{
          width: "75px",
          padding: "0 0.5rem 1rem 0",
          verticalAlign: "top",
        }}
      >
        <img
          style={{
            width: "75px",
            height: "75px",
            border: "3px solid #36b37e",
            borderRadius: "50%",
          }}
          src={user?.profileImg?.url || "/imgs/default-user-pic.png"}
          alt="Img profile"
        />
      </td>
      <td style={{ width: "max-content", verticalAlign: "top" }}>
        <h2 style={style.infoUserH2}>
          {user?.firstName} {user?.lastName?.split("")[0] || "J"}.
        </h2>
        <h3 style={style.infoUserH3}>{passport.TITLE2}</h3>
        <div style={style.headerType}>{level}</div>
      </td>
      <td style={{ verticalAlign: "top" }}>
        <h2 style={style.title}>{passport.TITLE1}</h2>
      </td>
    </tr>
  );
};
