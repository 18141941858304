import React, { useEffect, useState } from "react";
import useAuth from "src/app/shared/hooks/useAuth";
import {
  CannabisDispensariesService,
  StatesService,
} from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";

import "./settings.css";
import { General } from "./components/general/General";
import { EmailAddresses } from "./components/emailAddresses/EmailAddresses";
import { ChangePassword } from "./components/changePassword/ChangePassword";
import { createEventGA } from "src/app/shared/helpers/analytics";

const Settings = () => {
  const appType = AppType();
  const { user } = useAuth();

  const [cities, setCities] = useState([]);
  const [itemSelected, setItemSelected] = useState(0);
  const [isChange, setIsChange] = useState(false);
  const [dataSetting, setDataSetting] = useState();

  const statesService = new StatesService();
  const dispensaryService = new CannabisDispensariesService();

  const itemsSettings = [
    {
      name: "General",
      component: (
        <General data={dataSetting} cities={cities} setIsChange={setIsChange} />
      ),
    },
    {
      name: "Email addresses",
      component: (
        <EmailAddresses data={dataSetting} setIsChange={setIsChange} />
      ),
    },
    {
      name: "Change password",
      component: <ChangePassword />,
    },
  ];

  useEffect(() => {
    statesService
      .getStates()
      .then(([_, data]) => setCities(data))
      .catch((err) => console.error(err));
    dispensaryService
      .getInfo(user.username, appType)
      .then(([_, data]) => setDataSetting(data))
      .catch((err) => console.error(err));

    createEventGA(
      "Dispensary",
      "view_settings_dispensary",
      "View Settings dispensary"
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isChange &&
      dispensaryService
        .getInfo(user.username, appType)
        .then(([_, data]) => setDataSetting(data))
        .catch((err) => console.error(err));
    setIsChange(false);
  }, [isChange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="thcp-precheck-container settings-page">
      <div className="container">
        {itemSelected !== 0 && (
          <div className="back-setting" onClick={() => setItemSelected(0)}>
            <i className="pi pi-arrow-left" />
            Back
          </div>
        )}
        <h2>
          {itemSelected !== 0
            ? itemsSettings[itemSelected - 1].name
            : "Settings"}
        </h2>
        {itemSelected === 0 ? (
          <ul className="list-item">
            <li>
              <div
                className="container-list-item"
                onClick={() => setItemSelected(1)}
              >
                {itemsSettings[0].name}
                <i className="pi pi-arrow-right" />
              </div>
            </li>
            <li>
              <div
                className="container-list-item"
                onClick={() => setItemSelected(2)}
              >
                {itemsSettings[1].name}
                <div className="item-email">
                  {dataSetting?.email}
                  <i className="pi pi-arrow-right" />
                </div>
              </div>
            </li>
            <li>
              <div
                className="container-list-item"
                onClick={() => setItemSelected(3)}
              >
                {itemsSettings[2].name}
                <i className="pi pi-arrow-right" />
              </div>
            </li>
          </ul>
        ) : (
          itemsSettings[itemSelected - 1].component
        )}
      </div>
    </div>
  );
};

export default Settings;
