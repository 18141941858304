import React, { useEffect, useState } from "react";
import CannabisDispensariesCard from "../dispensaries-card";
import { DispensariesCarousel } from "../dispensaries-carousel/DispensariesCarousel";

import "./dispensaries-partner.css";

const DispensariesPartner = ({
  // searchText,
  canDispensaries,
  setPrecheckDialogVisible,
  setSelectedDispensary,
}) => {
  return (
    <>
      <p className="title-partner">
        <span>Partner</span> Dispensary
      </p>
      <DispensariesCarousel dispensaries={canDispensaries} />
    </>
  );
};

export default DispensariesPartner;
