import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Steps } from "primereact/steps";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";

import useAuth from "src/app/shared/hooks/useAuth";
import {
  AuthService,
  CannabisDispensariesService,
  StatesService,
} from "src/app/shared/services";
import { validateStepOne, validateStepTwo } from "./signup-form";
import { FileUpload } from "primereact/fileupload";
import { AppType } from "src/app/shared/hooks/AppType";

import "./signup-form.css";
import { setCookie } from "src/app/shared/utils/cookies.utils";
import { createEventGA } from "src/app/shared/helpers/analytics";

const SignupForm = () => {
  const { setUser } = useAuth();
  const appType = AppType();
  const toast = useRef(null);
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [submittedOne, setSubmittedOne] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isViewPass, setIsViewPass] = useState(false);
  const [isViewPassConf, setIsViewPassConf] = useState(false);

  const [isActiveNext, setIsActiveNext] = useState(false);

  const [stepOneData, setStepOneData] = useState({
    password: "",
    password2: "",
    username: "",
  });
  const [stepTwoData, setStepTwoData] = useState({
    name: "",
    address: "",
    state: "",
    zipCode: "",
    phoneNumber: "",
    email: "",
    webSite: "",
    retailLicence: "",
  });

  const items = [
    {
      label: "Account Details",
    },
    {
      label: "Dispensary Details",
    },
  ];

  useEffect(() => {
    setIsActiveNext(validateStepOne(stepOneData));
  }, [stepOneData]);

  const onStepOneChange = (field) => (e) => {
    const value = e.target.value;
    setStepOneData({ ...stepOneData, [field]: value });
  };

  const onStepTwoChange = (field) => (e) => {
    const value = e.target.value;
    setStepTwoData({ ...stepTwoData, [field]: value });
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/dispensary/signin");
  };

  const handleNext = (e) => {
    e.preventDefault();
    setSubmittedOne(true);
    setActiveIndex(1);
  };

  const handleDropdownChange = (field) => (e) => {
    const value = e.value;
    const selectedCity = cities.find((c) => c.code === value);
    setSelectedCity(value);
    setStepTwoData({ ...stepTwoData, [field]: selectedCity });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!validateStepTwo(stepTwoData)) {
      return;
    }
    const authService = new AuthService();
    const [error, signUpRes] = await authService.dispensarySignUp({
      ...stepTwoData,
      ...stepOneData,
      dispensaryType: "thcp_administrator", //TODO: This is the moment, wait new process for singnUp dispensary
      isTesting: !(process.env.REACT_APP_ENV === "production"),
    });
    if (error) {
      return;
    }
    setUser({
      token: signUpRes.token,
      rol: signUpRes.rol,
      username: signUpRes.username,
    });
    setCookie("thcp-access-dispensary", signUpRes.token);
    localStorage.setItem(
      "thcp_user",
      JSON.stringify({
        token: signUpRes.token,
        rol: signUpRes.rol,
        username: signUpRes.username,
      })
    );
    createEventGA("Dispensary", "signup_dispensary", "Sign-up dispensary");
    navigate("/dispensary/dashboard", { replace: true });
  };

  const statesService = new StatesService();

  useEffect(() => {
    statesService
      .getStates()
      .then(([_, data]) => setCities(data))
      .catch((err) => console.error(err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUploadImage = async (event) => {
    const formData = new FormData();
    const dispensaryService = new CannabisDispensariesService();
    formData.append("logo", event.files[0]);

    const [err, uploadRes] = await dispensaryService.uploadLogoImage(
      formData,
      appType
    );
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error uploading image",
        life: 1500,
      });
      event.options.clear();
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Image loaded successfully",
      life: 1500,
    });
    event.options.clear();
    setStepTwoData({ ...stepTwoData, logo: uploadRes.url });
  };

  return (
    <div className="thcp-dispensary-signup-container">
      <Toast ref={toast}></Toast>
      <Steps
        className="thcp-signup-steps"
        model={items}
        activeIndex={activeIndex}
        // onSelect={(e) => setActiveIndex(e.index)}
        readOnly={false}
      />
      <form className="thcp-dispensary-signup-form">
        {activeIndex === 0 && (
          <>
            <span className="p-input-icon-left">
              <i className="pi pi-user" />
              <InputText
                value={stepOneData.username}
                onChange={onStepOneChange("username")}
                className={submittedOne && !stepOneData.username && "p-invalid"}
              />
              <span className="thcp-float-label">Username</span>
            </span>
            <span className="p-input-icon-left">
              <i
                className={`pi
                ${isViewPass ? "pi-eye-slash" : "pi-eye"}
                icons-view-pass`}
                onClick={() => setIsViewPass(!isViewPass)}
              />
              <InputText
                value={stepOneData.password}
                onChange={onStepOneChange("password")}
                feedback={false}
                type={!isViewPass ? "password" : null}
                className={
                  submittedOne && !stepOneData.password
                    ? "thcp-input-password p-invalid"
                    : "thcp-input-password"
                }
              />
              <span className="thcp-float-label">Password</span>
            </span>
            <span className="p-input-icon-left">
              <i
                className={`pi
                ${isViewPassConf ? "pi-eye-slash" : "pi-eye"}
                icons-view-pass`}
                onClick={() => setIsViewPassConf(!isViewPassConf)}
              />
              <InputText
                value={stepOneData.password2}
                onChange={onStepOneChange("password2")}
                feedback={false}
                type={!isViewPassConf ? "password" : null}
                className={
                  submittedOne && !stepOneData.password2
                    ? "thcp-input-password p-invalid"
                    : "thcp-input-password"
                }
              />
              <span className="thcp-float-label">Confirm Password</span>
            </span>
            <div className="thcp-action-button">
              <Button
                onClick={handleBack}
                label="Back"
                icon="pi pi-angle-left"
                iconPos="left"
                className="thcp-form-button-next"
              />
              {isActiveNext && (
                <Button
                  onClick={handleNext}
                  label="Next"
                  icon="pi pi-angle-right"
                  iconPos="right"
                  className="thcp-form-button-next"
                />
              )}
            </div>
          </>
        )}
        {activeIndex === 1 && (
          <>
            <span className="p-input-icon-left">
              <InputText
                type="text"
                value={stepTwoData.name}
                onChange={onStepTwoChange("name")}
                className={submitted && !stepTwoData.name && "p-invalid"}
              />
              <span className="thcp-float-label">Name</span>
            </span>
            <span className="p-input-icon-left">
              <InputText
                type="text"
                value={stepTwoData.address}
                onChange={onStepTwoChange("address")}
                className={submitted && !stepTwoData.address && "p-invalid"}
              />
              <span className="thcp-float-label">Address</span>
            </span>
            <span className="p-input-icon-left">
              <Dropdown
                value={selectedCity}
                onChange={handleDropdownChange("state")}
                options={cities}
                optionLabel="name"
                optionValue="code"
                placeholder="Select a City"
                className="w-full md:w-14rem"
              />
              <span className="thcp-float-label">State</span>
            </span>
            <span className="p-input-icon-left">
              <InputText
                type="text"
                value={stepTwoData.zipCode}
                onChange={onStepTwoChange("zipCode")}
                placeholder="00000"
                className={submitted && !stepTwoData.zipCode && "p-invalid"}
              />
              <span className="thcp-float-label">ZIP Code</span>
            </span>
            <span className="p-input-icon-left">
              <InputText
                value={stepTwoData.phoneNumber}
                onChange={onStepTwoChange("phoneNumber")}
                placeholder="000-000-0000"
                className={submitted && !stepTwoData.phoneNumber && "p-invalid"}
              />
              <span className="thcp-float-label">Phone Number</span>
            </span>
            <span className="p-input-icon-left">
              <InputText
                type="email"
                value={stepTwoData.email}
                onChange={onStepTwoChange("email")}
                className={submitted && !stepTwoData.email && "p-invalid"}
              />
              <span className="thcp-float-label">Email</span>
            </span>
            <span className="p-input-icon-left">
              <InputText
                value={stepTwoData.webSite}
                onChange={onStepTwoChange("webSite")}
              />
              <span className="thcp-float-label">Website (optional)</span>
            </span>
            <span className="p-input-icon-left">
              <InputText
                value={stepTwoData.retailLicence}
                onChange={onStepTwoChange("retailLicence")}
              />
              <span className="thcp-float-label">
                Retail Licence # (optional)
              </span>
            </span>
            <span className="p-input-icon-left logo">
              <span className="">Logo</span>
              <FileUpload
                className=""
                mode="basic"
                name="logo"
                accept="image/*"
                customUpload
                auto
                uploadHandler={handleUploadImage}
              />
            </span>
            <div className="thcp-action-button">
              <Button
                onClick={() => setActiveIndex(0)}
                label="Back"
                icon="pi pi-angle-left"
                iconPos="left"
                className="thcp-form-button-next"
              />
              <Button
                onClick={handleSubmit}
                label="Register"
                className="thcp-form-button-submit"
              />
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default SignupForm;
