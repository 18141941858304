// import { useEffect, useState } from "react";
import { TYPES_TERPENS } from "src/app/constants/terpenes.constants";
import "../products-list.css";

export const CardProductDialog = ({ product }) => {
  return (
    <div
      className={`container-card-product-dialog `}
    >
      <div className="container-card-product-img">
        <div className="type-category">{product.category.toUpperCase()}</div>
        <img src={product.profileImg.url} alt="" />
      </div>
      <div className="container-card-product-info">
        <div className="container-card-product-name">
          <p>{product.name || "product"}</p>
        </div>
        <p>{product.description}</p>
      </div>
      <div className="container-card-product-terpene">
        <div className="container-card-product-terpene-img">
          <img
            src={`/imgs/terpens/${
              TYPES_TERPENS[product.terpene.toUpperCase()]?.img
            }`}
            alt=""
          />
        </div>
        <div className="container-card-product-terpene-info">
          <div className="container-card-product-terpene-info-name">
            <p
              style={{
                color: TYPES_TERPENS[product.terpene.toUpperCase()]?.color,
              }}
            >
              {product.terpene}
            </p>
          </div>
          <p className="container-card-product-terpen-sub">
            {TYPES_TERPENS[product.terpene.toUpperCase()]?.flavor || ""}
          </p>
          <div className="container-card-product-effect">
            {TYPES_TERPENS[`${product.terpene.toUpperCase()}`]?.effect || ""}
          </div>
        </div>
      </div>
    </div>
  );
};
