import { Button } from "primereact/button";
import { UserService } from "src/app/shared/services";
import { encryptedData } from "src/app/shared/helpers/encrypt";
import useAuth from "src/app/shared/hooks/useAuth";
import { AppType } from "src/app/shared/hooks/AppType";
import "./card-change-dispensaries.css";
import { createEventGA } from "src/app/shared/helpers/analytics";

export const CardChangeDispensaries = ({
  data,
  selected,
  setSelected,
  toast,
}) => {
  const { user, setUser } = useAuth();
  const appType = AppType();

  const handleSelected = async () => {
    const userService = new UserService();
    const dataEncrypt = encryptedData(data?.name);

    const [err] = await userService.updateDispensaryPreferred(
      user?.username,
      { data: dataEncrypt },
      appType
    );
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating the preferred dispensary",
        life: 1500,
      });
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Dispensary preferred updated",
      life: 1500,
    });
    createEventGA(
      "User",
      "update_dispensary_preferred",
      "Update Dispensary Preferred"
    );
    setSelected(data?.name);
    setUser({ ...user, prodReccDisp: data?.name });
  };

  return (
    <div className="container-card-dispensarie">
      <div className="container-card-dispensarie-img-info">
        <img src={data?.imgUrl || "/imgs/no-image.png"} alt="" />
        <div className="container-card-dispensarie-info">
          <p className="container-card-4dispensarie-info-name">{data?.name}</p>
          <div className="location-card">
            <img src={"/icons/v2/icon-location-black.png"} alt="logo" />
            <p className="container-card-dispensarie-info-location">
              {data?.location || "No location"}
            </p>
          </div>
        </div>
      </div>
      <div className="container-btn-change">
        <Button
          className={`${
            selected === data?.name ? "selected-disp" : "btn-select-change"
          } `}
          label={selected === data?.name ? "SELECTED" : "SELECT"}
          iconPos="left"
          icon={selected === data?.name ? "pi pi-check" : ""}
          onClick={() => handleSelected()}
        />
      </div>
    </div>
  );
};
