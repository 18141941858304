import "./appointment-date-picker.css";
import Popup from "reactjs-popup";
import { useContext } from "react";
import Calendar from "react-calendar";
import { DispensaryContext } from "../../pages/Dispensary";
export const AppointmentDatePicker = () => {
  const { appointmentDate, setAppointmentDate } = useContext(DispensaryContext);

  const minDate = new Date();
  const maxDate = new Date(new Date().setDate(minDate.getDate() + 15));
  return (
    <div className="appointment-date-picker-container">
      <Popup
        className="appointment-date-picker-popup"
        trigger={
          <button className="appointment-date-picker-button">
            <img src="/icons/v2/calendar.png" alt="calendar" />
            {appointmentDate.toDateString()}
          </button>
        }
        position="bottom center"
      >
        <Calendar
          onChange={(event) => {
            setAppointmentDate(event);
          }}
          locale="en"
          minDate={minDate}
          maxDate={maxDate}
        />
      </Popup>
    </div>
  );
};
