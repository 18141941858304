import { Card } from "primereact/card";
import React from "react";
import { passport } from "src/app/constants/passport.constants";

export const ActivityTemplate = ({ listActivity }) => {
  const style = {
    passportCard: {
      borderRadius: "12px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      fontFamily: '"Poppins", sans-serif',
      textAlign: "center",
      width: "139px",
      height: "100%",
      color: "#36b37e",
      padding: "0 .5rem",
    },
    titleCard: {
      color: "#36b37e",
      fontSize: "1.1rem",
      fontWeight: 700,
    },
    passportActivity: {
      background: "#f3d5b8",
      borderRadius: "12px",
      width: "110px",
      padding: "0 1rem",
      height: "24px",
      fontSize: "14px",
      fontWeight: "600",
      color: "#696c72",
      marginBottom: "0.3rem",
      alignItems: "center",
    },
  };

  return (
    <td style={{ padding: "0rem" }}>
      <Card
        style={style.passportCard}
        title={<span style={style.titleCard}>{passport.TITLE3}</span>}
      >
        {listActivity?.map((item, i) => (
          <div style={style.passportActivity} key={i}>
            {item}
          </div>
        ))}
      </Card>
    </td>
  );
};
