import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import "./avoidedCannabis.css";

export const AvoidedCannabis = ({ data, select, handleSelect }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setOptions(data?.options);
      select(Object.keys(data?.optionsSelected).length > 0);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickChoose = (id) => {
    handleSelect(data._id, id, true, 4);
  };
  return (
    <div className="container-preferences-avoid">
      <p className="title-step">{data?.title}</p>
      <p className="subtitle-step">({data?.subtitle})</p>
      <div className="options-avoid">
        {options?.map((e, index) => (
          <div
            className={`step4 ${e.selected ? "selected4" : ""}`}
            key={index}
            onClick={() => handleClickChoose(e?.id)}
          >
            <div className="items-avoid">
              <img
                src={`/icons/v2/steps/avoid/${e?.name}.png`}
                alt="icon-avoids"
              />
              <p>{e?.name}</p>
            </div>
            <Checkbox checked={e.selected} />
          </div>
        ))}
      </div>
    </div>
  );
};
