import { useEffect, useState } from "react";
// import NavBarComponent from "src/app/shared/components/navBar/navBar.component";
import { Link } from "react-router-dom";
// import { Header } from "./components/header/Header";
import { Activity } from "./components/activity/Activity";
import { Method } from "./components/method/Method";
import { CannabisType } from "./components/type/CannabisType";
import { Consumption } from "./components/consumption/Consumption";
import { Avoid } from "./components/avoid/Avoid";
import { PassportService } from "src/app/shared/services/passport.service";
import { AppType } from "src/app/shared/hooks/AppType";
import useAuth from "src/app/shared/hooks/useAuth";
import { createEventGA } from "src/app/shared/helpers/analytics";
import "./passport.css";

export const PassportPage = () => {
  const [passport, setPassport] = useState([]);
  const { user } = useAuth();
  const appType = AppType();

  useEffect(() => {
    const getUserPassport = async () => {
      const passportServices = new PassportService();
      const [_, passRes] = await passportServices.getPassport(
        user.username,
        appType
      );
      _ ? setPassport([]) : setPassport(passRes);
    };
    getUserPassport();
    createEventGA("User", "view_passport_user", "View Passport");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="thcp-profile-container-passport">
        <div className="thcp-profile-passport">
          <div className="container-btn">
            <Link
              to="/user/profile/cannabis-preferences"
              className="edit-preferences"
            >
              EDIT
            </Link>
          </div>
          <Activity listActivity={passport?.activity} />
          <Method listMethod={passport?.method} />
          <CannabisType listType={passport?.cannabis_type} />
          <Consumption listConsumption={passport?.consumption} />
          <Avoid listAvoid={passport?.avoid} />
        </div>
      </div>
    </>
  );
};
