import React from "react";
import NavBarComponent from "src/app/shared/components/navBar/navBar.component";

import "./layout.css";
import { ProfileHome } from "../profileHome";

const ProfilePage = () => {
  return (
    <>
      <NavBarComponent />
      <div className="thcp-profile-main-container">
        <ProfileHome /> 
      </div>
    </>
  );
};

export default ProfilePage;
