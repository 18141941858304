import { Dialog } from "primereact/dialog";
import { CardProductDialog } from "../products-list/card-product/CardProductDialog";
import "./dialog_container.css";

export const DialogProduct = ({ onOpen, setOnOpen, product }) => {
  return (
    <Dialog
      visible={onOpen}
      onHide={() => setOnOpen(false)}
      className="dialog-container-product"
    >
      <CardProductDialog product={product} handleOpenModal={false} />
      <div className="container-visit">
        <a
          className="visit-shop"
          href={product?.url && product?.url !== "" ? product?.url : "#"}
          target={product?.url && product?.url !== "" ? "_blank" : "_self"}
          rel="noopener noreferrer"
        >
          <i className="pi pi-home" />
          <p>Visit shop</p>
        </a>
      </div>
    </Dialog>
  );
};
