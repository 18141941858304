import { passport } from "src/app/constants/passport.constants";
import { Skeleton } from "primereact/skeleton";
import "./consumption.css";

export const Consumption = ({ listConsumption }) => {
  return (
    <>
      <div className="passport-item-container">
        <div className="passport-item-container-header">
          <img src={"/icons/v2/icon-consumption.png"} alt="logo" />
          <h3 className="title-item-passport">{passport.TITLE6}</h3>
        </div>
        {Object.keys(listConsumption || {}).length > 0 ? (
          <div className="passport-map-items fade-id">
            {Object.entries(listConsumption || {})?.map(([key, value], i) => (
              <div className="consumption-item" key={i}>
                <img
                  src={`/icons/v2/${
                    key === "Smoking"
                      ? "icon-consmoking"
                      : key === "Consumables"
                      ? "icon-conconsumable"
                      : "icon-contopical"
                  }.png`}
                  alt=""
                />
                <div>
                  <p className="con-value">{value}</p>
                  <p className="con-key">{key}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Skeleton width="100%" height="58px"></Skeleton>
        )}
      </div>
    </>
  );
};
