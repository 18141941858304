import { useState } from "react";
import { Card } from "primereact/card";
import { useEffect } from "react";
import { RadioButton } from "primereact/radiobutton";
import "./experience.css";
import { Skeleton } from "primereact/skeleton";

export const Experience = ({ data, select, handleSelect }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setOptions(data?.options);
      select(Object.keys(data?.optionsSelected).length > 0);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickChoose = (id) => {
    handleSelect(data._id, id, true, 1);
  };

  return (
    <div className="container-preferences-activity">
      {data?.title ? (
        <p className="title-step">{data?.title}</p>
      ) : (
        <Skeleton className="mb-2" width="100%" height="2rem"></Skeleton>
      )}
      <div className="container-options">
        {options.length > 0
          ? options?.map((e, i) => (
              <div
                key={i}
                className={`option-activity ${e.selected ? "selected" : ""}`}
                onClick={() => !e.selected && handleClickChoose(e?.id)}
              >
                <div className="container-info-opt">
                  <p
                    className={`title-opt ${
                      e.selected ? "selected-title-opt" : ""
                    }`}
                  >
                    {e?.name}
                  </p>
                  <p className="desc-opt">{e?.description}</p>
                  <div className="container-icons">
                    {Array.from({ length: e.id }).map((_, imgIndex) => (
                      <img
                        key={imgIndex}
                        src="/icons/thcp-verified.png"
                        alt={`logo ${imgIndex}`}
                        className="icons-img"
                      />
                    ))}
                  </div>
                </div>
                {e.selected ? (
                  <img src="/icons/v2/icon-check-opt.png" alt="" />
                ) : (
                  <RadioButton />
                )}
              </div>
            ))
          : Array.from({ length: 4 }, (_, i) => (
              <Skeleton key={i} width="100%" height="107px" />
            ))}
      </div>
    </div>
  );
};
