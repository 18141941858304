import React from "react";
import { Link } from "react-router-dom";
import "./find-dispensary.css";
import { createEventGA } from "src/app/shared/helpers/analytics";

export const BtnFindDispensary = ({ isOpenPreferences }) => {
  const handleFindDispensary = () => {
    createEventGA("User", "find_dispensarys_user", "Finder Dispensarys");
  };

  return (
    <div
      className={`container-btn-find ${isOpenPreferences ? "fixed-find" : ""}`}
    >
      <Link
        className="container-btn-find-dispensary"
        to="/search-dispensaries"
        onClick={handleFindDispensary}
      >
        <img src="/icons/v2/icon-left-finder.png" alt="thcp_logo" />
        <p>DISPENSARY FINDER</p>
        <img src="/icons/v2/icon-rigth-finder.png" alt="thcp_logo" />
      </Link>
    </div>
  );
};
