import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { SupportService } from "src/app/shared/services/support.service";
import { AppType } from "src/app/shared/hooks/AppType";
import "./contact-us.css";

const ContactUsPage = () => {
  const appType = AppType();
  const toast = useRef(null);
  const [message, setMessage] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const supportService = new SupportService();
    const [err] = await supportService.contactUs({ message: message }, appType);
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error sending email",
        life: 1500,
      });
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Email sent successfully",
      life: 2500,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="container-settings-contact">
        <div className="settings-header">
          <Link to={"/user/profile/settings"} className="back-home">
            <i className="pi pi-angle-left" />
            Back
          </Link>
          <p>Contact Us</p>
        </div>
        <div className="settings-wrapper">
          <div className="settings-container">
            <div className="settings-info">
              <p className="settings-info_description">
                Send us your questions
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <form className="thcp-contact-us-form">
            <span className="p-input-icon-left">
              <InputTextarea
                autoResize
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={2}
                style={{
                  width: "100%",
                  fontSize: "1.4rem",
                  padding: "1rem 2rem",
                }}
                placeholder="Write your question..."
                className="thcp-input-password"
              />
            </span>
            <div className="thcp-form-action-button">
              <Button link label="Save" onClick={handleSubmit} />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
