import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_ENCRYPT_SECRET_KEY;

export const encryptedData = (data) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey
    ).toString();
    return encrypted;
  } catch (error) {
    return;
  }
};

export const decryptData = (encryptedText) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(originalText);
  } catch (error) {
    return;
  }
};
