import React, { useEffect, useState } from "react";
import SearchBar from "./components/search-bar";
import DispensariesPartner from "./components/dispensaries-partner";
import PrecheckDialog from "./components/precheck-dialog";
import {
  CannabisDispensariesService,
  CannabisDispensariesTestingService,
} from "../../shared/services/cannabis-dispensaries.service";
import { Link } from "react-router-dom";
import "./cannabis-dispensary.css";

import { DispensariesTop } from "./components/dispensaries-top/DispensariesTop";

const CannabisDispensaries = () => {
  const [searchText, setSearchText] = useState("");
  const [canDispensaries, setCanDispensaries] = useState([]);
  const [selectedDispensary, setSelectedDispensary] = useState({});
  const [precheckDialogVisible, setPrecheckDialogVisible] = useState(false);
  const cannabisDispensariesService = new CannabisDispensariesService();

  useEffect(() => {
    cannabisDispensariesService
      .search()
      .then(([err, res]) =>
        err
          ? setCanDispensaries([])
          : setCanDispensaries(
              process.env.REACT_APP_ENV === "production"
                ? res.filter((item) => !item.isTesting)
                : res
            )
      )
      .catch((_) => setCanDispensaries([]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="dispensary-header">
        <Link to={"/user/profile"} className="back-home-dispensary">
          <i className="pi pi-angle-left" />
          Back
        </Link>
        <p>Dispensary Finder</p>
        <div className="thcp-nav-container-icon">
          <Link to="/user/profile/settings">
            <img
              src="/icons/v2/setting.png"
              alt="THCP logo"
              className="thcp-nav-icon-settings"
            />
          </Link>
        </div>
      </div>
      {/* <SearchBar searchText={searchText} setSearchText={setSearchText} /> */}
      <DispensariesPartner
        // searchText={searchText}
        canDispensaries={canDispensaries.filter((item) => item.isPartner)}
      />

      <DispensariesTop
        dispensaries={canDispensaries.filter((item) => item.isTop)}
      />

      {precheckDialogVisible && (
        <PrecheckDialog
          precheckDialogVisible={precheckDialogVisible}
          setPrecheckDialogVisible={setPrecheckDialogVisible}
          selectedDispensary={selectedDispensary}
        />
      )}
    </>
  );
};

export default CannabisDispensaries;
