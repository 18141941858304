import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import useAuth from "src/app/shared/hooks/useAuth";
import { UserService } from "src/app/shared/services";
import { isOfAge, onlyRequiredUserData, validateInfo } from "./personal-info";
import { AppType } from "src/app/shared/hooks/AppType";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { DialogVerify } from "./components/dialogVerify/DialogVerify";
import { encryptedData } from "src/app/shared/helpers/encrypt";
import { createEventGA } from "src/app/shared/helpers/analytics";
import "./personal-info.css";

const PersonalInfoPage = () => {
  const { user, setUser } = useAuth();
  const toast = useRef(null);

  const appType = AppType();

  const [isFirstTime, setIsFirstTime] = useState(false);
  const [isAge, setIsAge] = useState(true);
  const [currentUser, setCurrentUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    birthDate: "",
  });

  const [dialogVisible, setDialogVisible] = useState(false);

  const genderOpts = [
    { value: "M", description: "Male" },
    { value: "F", description: "Female" },
  ];

  useEffect(() => {
    const userService = new UserService();
    userService
      .getCurrentUser(appType)
      .then(([err, data]) => {
        if (data?.cannabisPreferences.length === 0) {
          setIsFirstTime(true);
        }

        data?.cannabisPreferences.length === 0 &&
          data?.verifiedStatus !== "accept" &&
          setDialogVisible(true);

        setCurrentUser(onlyRequiredUserData(data));
      })
      .catch((err) => console.error(err));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onGenderAndBirthChange = (field) => (e) => {
    const value = e.value;
    setCurrentUser({ ...currentUser, [field]: value });
    field === "birthDate" && setIsAge(isOfAge(value));
    if (field === "birthDate") {
      const isAgeOfAge = isOfAge(value);
      setIsAge(isAgeOfAge);
      if (!isAgeOfAge) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "You must be at least 19 years old to proceed.",
          life: 1500,
        });
      }
    }
  };

  const onInputChange = (field) => (e) => {
    const value = e.target.value;
    setCurrentUser({ ...currentUser, [field]: value });
  };

  const handleUpdateUser = async () => {
    const userService = new UserService();
    const dataEncrypt = encryptedData(currentUser);

    const [err] = await userService.updateCurrentUser(
      user?.username,
      { data: dataEncrypt },
      appType
    );
    if (err) {
      !isFirstTime &&
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error updating user information",
          life: 1500,
        });
      createEventGA(
        "User",
        "update_pers_info_error_user",
        "Error Update personal info"
      );
      return;
    }
    !isFirstTime &&
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User info updated successfully",
        life: 1500,
      });
    createEventGA(
      "User",
      "update_pers_info_success_user",
      "Update personal info"
    );
    setUser({
      ...user,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="container-settings-personal-info">
        {!isFirstTime && (
          <div className="settings-header">
            <Link to={"/user/profile/settings"} className="back-home">
              <i className="pi pi-angle-left" />
              Back
            </Link>
            <p>Personal Info</p>
          </div>
        )}
        <div className="thcp-profile-preferences-container">
          <div className="thcp-profile-img-settings personal-info-img">
            <img
              className="thcp-profile-image-settings"
              src={user.profileImg?.url || "/imgs/default-user-pic.png"}
              alt="user_img"
            />
          </div>
          <form className="personal-info-form">
            <span className="p-input-icon-left">
              <InputText
                value={currentUser.firstName}
                onChange={onInputChange("firstName")}
              />
              <span className="thcp-float-label">Name</span>
            </span>
            <span className="p-input-icon-left">
              <InputText
                value={currentUser.lastName}
                onChange={onInputChange("lastName")}
              />
              <span className="thcp-float-label">Last name</span>
            </span>
            <span className="p-input-icon-left">
              <InputText value={currentUser.email} type="email" disabled />
              <span className="thcp-float-label">Email</span>
            </span>
            <span className="p-input-icon-left">
              <InputText
                value={currentUser.phoneNumber}
                placeholder="(+1) 000 000 0000"
                onChange={onInputChange("phoneNumber")}
              />
              <span className="thcp-float-label">
                Phone number{" "}
                <Badge
                  value="optional"
                  style={{
                    background: "#DFDFDF",
                    color: "#000",
                    fontWeight: 100,
                  }}
                ></Badge>
              </span>
            </span>
            <span className="p-input-icon-left">
              <Dropdown
                value={currentUser.gender}
                placeholder="Select"
                className="w-full md:w-14rem"
                options={genderOpts}
                optionLabel="description"
                optionValue="value"
                onChange={onGenderAndBirthChange("gender")}
              />
              <span className="thcp-float-label">
                Gender{" "}
                <Badge
                  value="optional"
                  style={{
                    background: "#DFDFDF",
                    color: "#000",
                    fontWeight: 100,
                  }}
                ></Badge>
              </span>
            </span>
            <span className="p-input-icon-right">
              <Calendar
                value={
                  currentUser?.birthDate
                    ? new Date(currentUser.birthDate)
                    : null
                }
                prevIcon={<i className="pi pi-angle-left colorIconCalendar" />}
                nextIcon={<i className="pi pi-angle-right colorIconCalendar" />}
                onChange={onGenderAndBirthChange("birthDate")}
                showIcon
                touchUI
                maxDate={new Date()}
                className={`thcp-profile-info-calendar ${
                  isAge ? "" : "p-invalid"
                } `}
              />
              <span className="thcp-float-label">
                Birthday{" "}
                <Badge
                  value="optional"
                  style={{
                    background: "#DFDFDF",
                    color: "#000",
                    fontWeight: 100,
                  }}
                ></Badge>
              </span>
            </span>
          </form>
          <div className="thcp-profile-nav-buttons personal-info-buttons">
            <Link
              to={
                isFirstTime &&
                validateInfo(currentUser) &&
                "/user/profile/cannabis-preferences"
              }
              className="thcp-profile-pref-link"
            >
              <Button
                className={`personal-info-buttons_save ${
                  validateInfo(currentUser) ? "" : "buttons-done-disabled"
                } `}
                onClick={handleUpdateUser}
                disabled={!validateInfo(currentUser)}
              >
                {isFirstTime ? "Done" : "Save"}
              </Button>
            </Link>
          </div>
        </div>
      </div>
      {dialogVisible && (
        <DialogVerify
          dialogVisible={dialogVisible}
          setDialogVisible={setDialogVisible}
          path={"info"}
        />
      )}
    </>
  );
};

export default PersonalInfoPage;
