export const adjustDateToSpecificTime = (date, timeStr) => {
  const resultDate = new Date(date.getTime());
  const [time, period] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");

  hours = parseInt(hours);
  minutes = parseInt(minutes);

  if (period === "PM" && hours < 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  resultDate.setHours(hours, minutes, 0, 0);

  return resultDate;
};
