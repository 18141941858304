export const TYPES_TERPENS = {
  MYRCENE: {
    flavor: "Herbal-Hops",
    effect: "Calming",
    img: "Myrcene.jpg",
    color: "#227E12",
  },
  CARYOPHELLENE: {
    flavor: "Pepper-Cloves",
    effect: "Pain Relief",
    img: "Caryophelene.png",
    color: "#756B38",
  },
  LIMONENE: {
    flavor: "Citrus-Lemon",
    effect: "Uplifting",
    img: "Limonene.jpg",
    color: "#89D32A",
  },
  PINENE: {
    flavor: "Pine-Wood",
    effect: "Focus",
    img: "Pinene.jpeg",
    color: "#756B38",
  },
  TERPINOLENE: {
    flavor: "Fruity-Apples",
    effect: "Sedative",
    img: "Terpinolene.png",
    color: "#DB6A6A",
  },
  LINALOOL: {
    flavor: "Floral-Lavender",
    effect: "Calming",
    img: "Linalool.jpeg",
    color: "#6C6ADB",
  },
  "TERPENE BLEND": {
    flavor: "",
    effect: "Calming",
    img: "Terpene Blend.jpg",
    color: "#227E12",
  },
};
