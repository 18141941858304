import authClient from "../utils/auth-cookie-client.utils";

export class PreferenceService {
  async getPreferences(appType) {
    try {
      const response = await authClient(appType).get("/cannabis-preferences");
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
}
