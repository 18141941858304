import React, { useEffect, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
// import { Badge } from 'primereact/badge';

import { PrecheckService } from "src/app/shared/services";
import { parsePrecheckRes } from "../../../utils/parse-precheck.map";

import "./precheck-history.css";
import { AppType } from "src/app/shared/hooks/AppType";
import { createEventGA } from "src/app/shared/helpers/analytics";

const PrecheckHistory = ({ setActiveDialog, setSelectedProfile }) => {
  const appType = AppType();
  const [products, setProducts] = useState([]);

  const precheckService = new PrecheckService();

  const handleViewProfile = (rowData) => () => {
    setActiveDialog(true);
    createEventGA(
      "Dispensary",
      "view_passport_dispensary",
      "View Passport dispensary"
    );
    setSelectedProfile(rowData);
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <div
        className="thcp-table-preference-container"
        style={{ minWidth: "22rem" }}
      >
        <Button
          className="thcp-button-profile"
          label="View Passport"
          onClick={handleViewProfile(rowData)}
          text
        />
      </div>
    );
  };

  useEffect(() => {
    precheckService
      .getPrevious(appType)
      .then(([_, data]) => setProducts(parsePrecheckRes(data)))
      .catch(([err]) => console.error(err));

    createEventGA(
      "Dispensary",
      "view_history_dispensary",
      "View History dispensary"
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clientNameTemplate = (rowData) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        className="thcp-table-profile-img"
        src={rowData.customerProfileImg || "/imgs/default-user-pic.png"}
        alt="user_img"
      />
      <span>{rowData.fullName}</span>
    </div>
  );

  return (
    <div className="thcp-precheck-container">
      <div className="thcp-precheck-table-container">
        <h2>History</h2>
        <div className="card-content">
          <DataTable
            value={products}
            className="thcp-precheck-table"
            paginator
            rows={10}
            emptyMessage="No thcprechecks found."
          >
            <Column
              className="thcp-first"
              field="fullName"
              header="Client"
              body={clientNameTemplate}
              style={{ width: "220px" }}
            />
            <Column
              style={{ minWidth: "10rem" }}
              field="dateVisit"
              header="Date of visit"
            />
            <Column
              style={{ minWidth: "11rem" }}
              field="timeOfVisit"
              header="Time of visit"
            />
            <Column
              style={{ minWidth: "11rem" }}
              field="date"
              header="Date"
              body={(rowData) =>
                rowData.date ? new Date(rowData.date).toDateString() : "No date"
              }
            />
            <Column
              field="quantity"
              header="Passport"
              body={statusBodyTemplate}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default PrecheckHistory;
