import React from "react";
import { Link } from "react-router-dom";
import "./change-dispensaries.css";
import { DispensariesList } from "./components/dispensaries";

export const ChangeDispensary = () => {
  return (
    <div className="container-change-dispensary">
      <div className="container-change-dispensary-header">
        <Link to="/user/profile" className="back-change-btn-header">
          <i className="pi pi-angle-left" />
          Back
        </Link>
        <p>Dispensary</p>
      </div>
      <DispensariesList />
    </div>
  );
};
