import React from "react";
import { Button } from "primereact/button";

import "./dispensaries-card.css";
import { createEventGA } from "src/app/shared/helpers/analytics";
import { Link } from "react-router-dom";
import {
  DispensaryName,
  DispensaryRating,
  DispensaryState,
} from "../dispensary-info/DispensaryInfo";

const CannabisDispensariesCard = ({
  dispensary,
  // setPrecheckDialogVisible,
  // setSelectedDispensary,
}) => {
  const handlePrecheck = () => {
    createEventGA("User", "precheck_init_user", "Init Process Precheck");
    // setSelectedDispensary(dispensary);
    // setPrecheckDialogVisible(true);
  };

  return (
    <Link
      to={`/search-dispensaries/${dispensary._id}`}
      className="link-unstyled"
    >
      <div className="thcp-card-container">
        <div className="thcp-card-image">
          <img
            src={
              dispensary.imgUrl ||
              "https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png"
            }
            alt="Cannabis dispensary img"
          />
        </div>
        <div className="thcp-card-info">
          <DispensaryName
            name={dispensary.name}
            isPartner={dispensary.isPartner}
          />
          <DispensaryRating rating={dispensary.rating} />
          <DispensaryState
            isoCode={dispensary.state.isoCode}
            city={dispensary.city}
          />
          {dispensary.isPartner && <AppointmentButton />}
        </div>
      </div>
    </Link>
  );
};

const AppointmentButton = () => {
  return <Button className="thcp-card-button" label="APPOINTMENT" />;
};

export default CannabisDispensariesCard;
