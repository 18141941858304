import { decryptData } from "../helpers/encrypt";
import authClient from "../utils/auth-cookie-client.utils";

export class ReportService {
  async register(payload, appType) {
    try {
      const { data } = await authClient(appType).post(
        "/reports/compliance",
        payload
      );
      const dataDecrypt = decryptData(data?.payload);
      return [null, dataDecrypt];
    } catch (error) {
      console.error("register error::", error);
      return [error];
    }
  }

  async getByDispensary(userId, appType) {
    try {
      const { data } = await authClient(appType).get("/reports/" + userId);
      const dataDecrypt = decryptData(data?.payload);
      return [null, dataDecrypt];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async getByDocument(docId) {
    try {
      const response = await authClient().get("/reports/document" + docId);
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
}
