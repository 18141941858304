import { useContext, useState } from "react";
import { AppointmentDialog } from "../appointment-dialog/AppointmentDialog";
import { BudtendersListContext } from "./BudtendersList";
import "./budtender.css";
import { Toast } from "primereact/toast";
import { generateTimeSlots } from "src/app/shared/helpers/dates/generate-time-slots";
import { DispensaryContext } from "../../pages/Dispensary";
import { getDayOfWeek } from "src/app/shared/helpers/dates/get-date-of-week";
import { isToday } from "date-fns";
import { filterPastTimes } from "src/app/shared/helpers/dates/filter-past-dates";
import { areDatesEquals } from "src/app/shared/helpers/dates/are-dates-equals";
import { getOnlyHour } from "src/app/shared/helpers/dates/get-only-hour";

export const Budtender = ({ budtender }) => {
  const { dispensary, appointmentDate, dispensaryPrechecks } =
    useContext(DispensaryContext);
  const {
    selectedBudtender,
    handleBudtenderSelected,
    toast,
    getBudtenderFullName,
  } = useContext(BudtendersListContext);

  const fullName = getBudtenderFullName(budtender);
  const isSelected = budtender?._id === selectedBudtender;
  const day = getDayOfWeek(appointmentDate);
  let timeSlots = generateTimeSlots({
    start: dispensary.schedule[day]?.from,
    end: dispensary.schedule[day]?.to,
  });

  if (isToday(appointmentDate)) {
    timeSlots = filterPastTimes(timeSlots);
  }
  const existingTimeframesOnAppointmentDate = dispensaryPrechecks
    .filter((precheck) => {
      return (
        areDatesEquals(precheck.date, appointmentDate) &&
        precheck.budtender === budtender?._id
      );
    })
    .map((precheck) => {
      return getOnlyHour(new Date(precheck.date));
    });
  timeSlots = timeSlots.filter(
    (time) => !existingTimeframesOnAppointmentDate.includes(time)
  );
  return (
    <>
      <Toast ref={toast} />
      <div
        className={`budtender-info-container ${isSelected ? "selected" : ""}`}
        onClick={() => handleBudtenderSelected(budtender?._id)}
      >
        <img
          className="budtender-img"
          src={budtender?.profileImg?.url || "/icons/v2/profile.png"}
          alt="profile-icon"
        />
        <p className="budtender-name">{fullName}</p>
        <input
          type="radio"
          value={budtender?._id}
          checked={isSelected}
          readOnly
        />
      </div>
      {isSelected && (
        <AttentionSchedule budtender={budtender} timeSlots={timeSlots} />
      )}
    </>
  );
};

const AttentionSchedule = ({ budtender, timeSlots }) => {
  const [dialogInfo, setDialogInfo] = useState({
    time: null,
    budtender: null,
    isDialogVisible: false,
  });
  const handleButtonClick = (time) => {
    setDialogInfo({ time, isDialogVisible: true });
  };
  return (
    <>
      <div className="attention-schedule">
        {timeSlots.map((time) => (
          <button
            onClick={() => handleButtonClick(time)}
            key={time}
            className="attention-schedule-time"
          >
            {time}
          </button>
        ))}
      </div>
      <AppointmentDialog
        isDialogVisible={dialogInfo.isDialogVisible}
        setIsDialogVisible={setDialogInfo}
        time={dialogInfo?.time}
        budtender={budtender}
      />
    </>
  );
};
