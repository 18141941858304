import { HeaderTemplate } from "./components/headerTemplate/HeaderTemplate";
import { ActivityTemplate } from "./components/activityTemplate/ActivityTemplate";
import { MethodTemplate } from "./components/methodTemplate/MethodTemplate";
import { ConsumptionTemplate } from "./components/consumptionTemplate/ConsumptionTemplate";
import { AvoidTemplate } from "./components/avoidTemplate/AvoidTemplate";
import { getDateTodayFormat } from "./template-precheck";
import { CannabisTypeTemplate } from "./components/cannabisTypeTemplate/CannabisTypeTemplate";

export const TemplatePrecheck = ({ passport, user, dispensary, time, uid }) => {
  return (
    <div>
      <div>
        <h1
          style={{
            color: "#000000",
            fontWeight: "mixed",
            margin: "0",
            textAlign: "center",
          }}
        >
          Appointment reminder
        </h1>
        <h2
          style={{
            color: "#000000",
            fontWeight: "400",
            margin: "0",
            textAlign: "center",
          }}
        >
          Thanks for using THC Precheck!
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "3rem",
          gap: "4rem",
          height: "100%",
        }}
      >
        <div
          style={{
            width: "396px",
            height: "max-content",
            borderRadius: "12px",
            padding: "1rem",
            // "box-shadow": "rgba(0, 0, 0, 0.35) 0px 4px 10px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            marginRight: "1rem",
          }}
        >
          <table
            role="presentation"
            cellspacing="0"
            cellpadding="0"
            border="0"
            width="100%"
            style={{ margin: "0" }}
          >
            <HeaderTemplate level={passport?.level} user={user} />
          </table>
          <table
            role="presentation"
            cellspacing="0"
            cellpadding="0"
            border="0"
            width="100%"
            style={{ margin: "0" }}
          >
            <ActivityTemplate listActivity={passport?.activity} />
            <MethodTemplate listMethod={passport?.method} />
          </table>
          <table
            role="presentation"
            cellspacing="0"
            cellpadding="0"
            border="0"
            width="100%"
            style={{ marginTop: "1rem" }}
          >
            <tr style={{ padding: 0, height: "max-content" }}>
              <CannabisTypeTemplate listType={passport?.cannabis_type} />
            </tr>
          </table>
          <table
            role="presentation"
            cellspacing="0"
            cellpadding="0"
            border="0"
            width="100%"
            style={{ marginTop: "1rem" }}
          >
            <ConsumptionTemplate listConsumption={passport?.consumption} />
            <AvoidTemplate listAvoid={passport?.avoid} />
          </table>
        </div>
        <div style={{ width: "250px", height: "100%" }}>
          <div>
            <h1
              style={{
                textAlign: "start",
                fontSize: "28px",
                margin: 0,
                color: "black",
              }}
            >
              {user?.firstName} {user?.lastName}
            </h1>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                margin: "0",
                gap: "5px",
              }}
            >
              <h4 style={{ margin: 0, color: "black" }}>
                {user.verifiedStatus === "accept" ? "Verified" : "Not verified"}
              </h4>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/thcprecheck.appspot.com/o/THCPrecheckLogoSheet.png?alt=media&token=9a040e94-35f3-46c3-a9ae-34faab9bb1c3"
                alt="logo"
                style={{
                  width: "23px",
                  height: "19px",
                }}
              />
            </div>
            <h3
              style={{ textAlign: "start", marginTop: "4px", color: "black" }}
            >
              Confirmation # : <span style={{ color: "#FAA21A" }}>{uid}</span>
            </h3>
          </div>
          <hr />
          <div style={{ marginBottom: "1rem" }}>
            <h1
              style={{
                textAlign: "start",
                fontSize: "25px",
                margin: 0,
                color: "black",
              }}
            >
              {getDateTodayFormat()}
            </h1>
            <div style={{ display: "flex", gap: "1rem" }}>
              <img
                src="https://i.ibb.co/Zf1sfPQ/date.png"
                alt="logo"
                style={{
                  width: "35px",
                  height: "35px",
                  filter: "hue-rotate(45deg) brightness(110%)",
                }}
              />
              <h1
                style={{
                  textAlign: "start",
                  fontSize: "25px",
                  margin: 0,
                  paddingLeft: "1rem",
                  color: "#FAA21A",
                }}
              >
                {time?.split(" - ")[0]}
              </h1>
            </div>
          </div>
          <hr />
          <div>
            <h1
              style={{
                textAlign: "start",
                fontSize: "25px",
                margin: 0,
                color: "#36B37E",
              }}
            >
              {dispensary?.name}
            </h1>
            <h4 style={{ margin: 0, textAlign: "start", color: "black" }}>
              {dispensary?.location}
            </h4>
            <a
              href="https://app.thcprecheck.com/dispensary/dashboard"
              style={{
                background: "#36B37E",
                border: "none",
                marginTop: "2rem",
                width: "max-content",
                height: "max-content",
                padding: "5px",
                color: "#FFFFFF",
                fontSize: "24px",
                display: "flex",
                alignItems: "flex-start",
                textDecoration: "none",
              }}
              rel="noreferrer"
              target="_blank"
            >
              Go to Dashboard
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
