import { Dialog } from "primereact/dialog";
import "./appointment-dialog.css";
import { useContext } from "react";
import ReactDOMServer from "react-dom/server";
import { BudtendersListContext } from "../budtender/BudtendersList";
import { useNavigate } from "react-router-dom";
import { DispensaryContext } from "../../pages/Dispensary";
import { PrecheckService } from "src/app/shared/services";
import { TemplatePrecheck } from "../template-precheck";
import { PassportService } from "src/app/shared/services/passport.service";
import useAuth from "src/app/shared/hooks/useAuth";
import { AppType } from "src/app/shared/hooks/AppType";
import { createNumberConfirmation } from "../precheck-dialog/precheck-dialog";
import { encryptedData } from "src/app/shared/helpers/encrypt";
import { createEventGA } from "src/app/shared/helpers/analytics";
import { adjustDateToSpecificTime } from "src/app/shared/helpers/dates/adjust-date";
import { LoaderContext } from "src/context/loader/loaderContext";
export const AppointmentDialog = ({
  isDialogVisible,
  setIsDialogVisible,
  time,
  budtender,
}) => {
  const { user } = useAuth();
  const appType = AppType();
  const { selectedBudtender, toast, getBudtenderFullName } = useContext(
    BudtendersListContext
  );
  const { appointmentDate, dispensary } = useContext(DispensaryContext);
  const { setIsLoading } = useContext(LoaderContext);
  const handleButtonClick = async () => {
    try {
      setIsLoading(true);
      const uid = createNumberConfirmation();
      const passportServices = new PassportService();
      const [_, passport] = await passportServices.getPassport(
        user?.username,
        appType
      );
      const templateHTML = ReactDOMServer.renderToString(
        <TemplatePrecheck
          passport={passport}
          user={user}
          dispensary={dispensary}
          time={dispensary.reservations.find((r) => r.id === time)?.description}
          uid={uid}
        />
      );
      const precheckService = new PrecheckService();
      const payload = {
        timeFrame: time,
        serviceType: "In-store",
        dispensary: {
          id: dispensary?._id,
          name: dispensary?.name,
          companyId: dispensary?.companyId,
        },
        confirmation: uid,
        template: templateHTML,
        budtender: selectedBudtender,
        date: adjustDateToSpecificTime(appointmentDate, time),
      };
      const dataEncrypt = encryptedData(payload);
      const [error] = await precheckService.register(
        { data: dataEncrypt },
        appType
      );
      if (!error) {
        toast.current.show({
          severity: "success",
          summary: "Appointment Confirmed",
          detail: `Your appointment has been confirmed with ${getBudtenderFullName(
            selectedBudtender
          )} at ${appointmentDate.toLocaleDateString()} ${time}`,
          life: 3000,
        });

        createEventGA("User", "precheck_success_user", "Precheck User Succes");
      } else {
        //TODO: handle error
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            error.response.data.isVerified === false
              ? "You must verify your identity in your profile"
              : "Error in confirming appointment",
          life: 3000,
        });
      }
      setIsLoading(false);
      setIsDialogVisible(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Dialog
      header={<h2>Confirm Appointment</h2>}
      visible={isDialogVisible}
      onHide={() => setIsDialogVisible(false)}
      className="thcp-appointment-dialog-content"
      draggable={false}
    >
      <div className="thcp-appointment-content">
        <p>
          Do you want to confirm your appointment with{" "}
          <b>{getBudtenderFullName(selectedBudtender)},</b> at:{" "}
          <b>
            {appointmentDate.toLocaleDateString()} {time}
          </b>
          ?
        </p>
        <button onClick={handleButtonClick}>Confirm</button>
      </div>
    </Dialog>
  );
};
