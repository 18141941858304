import { getOnlyHour } from "./get-only-hour";

export const generateTimeSlots = (timeRange) => {
  if (!timeRange.start || !timeRange.end) {
    return [];
  }
  const result = [];
  const startTime = timeRange.start.split(":");
  const endTime = timeRange.end.split(":");

  let currentDate = new Date();
  currentDate.setHours(parseInt(startTime[0]), parseInt(startTime[1]), 0, 0);

  let endDate = new Date();
  endDate.setHours(parseInt(endTime[0]), parseInt(endTime[1]), 0, 0);

  while (currentDate <= endDate) {
    const formattedTime = getOnlyHour(currentDate);
    result.push(formattedTime);
    currentDate.setMinutes(currentDate.getMinutes() + 30);
  }

  return result;
};
