import React, { useEffect, useRef, useState } from "react";
import { CardProduct } from "./card-product/CardProduct";
import { Link } from "react-router-dom";
import { ProductService } from "src/app/shared/services/product.service";
import { AppType } from "src/app/shared/hooks/AppType";
import useAuth from "src/app/shared/hooks/useAuth";
import { Skeleton } from "primereact/skeleton";
import { DialogProduct } from "../dialog-product";
import "./products-list.css";

export const ProductsListRecommendations = ({
  isOpenPreferences,
  isReflesh,
  setIsReflesh,
}) => {
  const { user } = useAuth();
  const appType = AppType();

  const containerRef = useRef(null);

  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [filterProd, setFilterProd] = useState([]);
  const [isNotProduct, setIsNotProduct] = useState(false);
  const [onOpenModal, setOnOpenModal] = useState(false);
  const [productScrollPosition, setProductScrollPosition] = useState(0);

  const handleOpenModal = (product) => {
    setProduct(product);
    setOnOpenModal(true);
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollLeft } = containerRef.current;
      scrollLeft > 0 && scrollLeft < 130
        ? setProductScrollPosition(0)
        : scrollLeft > 130 && scrollLeft < 300
        ? setProductScrollPosition(1)
        : scrollLeft > 300 && scrollLeft < 480
        ? setProductScrollPosition(2)
        : scrollLeft > 480 && setProductScrollPosition(3);
    }
  };

  useEffect(() => {
    const getUserPassport = async () => {
      const productServices = new ProductService();
      const [_, prodRes] = await productServices.getProductRec(
        user.prodReccDisp,
        appType
      );
      _ ? setProducts([]) : setProducts(prodRes);
    };
    getUserPassport();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (products.length < 1) {
      setIsNotProduct(true);
    } else if (products.length > 0 || isReflesh) {
      const randomProd = products.sort(() => 0.5 - Math.random());
      setFilterProd([]);
      setIsNotProduct(false);
      setTimeout(() => {
        setFilterProd(randomProd.slice(0, 4));
        setIsReflesh(false);
      }, 100);
    }
  }, [products, isReflesh]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        // className={`container-prod-list ${
        //   isOpenPreferences ? "margin-bottom-fixed" : ""
        // }`}
        className={`container-prod-list`}
        ref={containerRef}
      >
        {filterProd.length > 0 ? (
          filterProd
            .slice(0, 4)
            .map((prod, i) => (
              <CardProduct
                key={i}
                product={prod}
                handleOpenModal={handleOpenModal}
              />
            ))
        ) : isNotProduct ? (
          <p className="text-dont-product">Don't have products</p>
        ) : (
          Array.from({ length: 4 }, (_, i) => (
            <Skeleton
              key={i}
              width="176px"
              height="318px"
              className="skeleton-card-prod"
            />
          ))
        )}

        {!isNotProduct && (
          <div
            className="container-prod-list-card-reflesh"
            onClick={() => setIsReflesh(true)}
          >
            <div>
              <p className="title-reflesh">Preferences</p>
              <p className="sub-reflesh">Reflesh your options</p>
            </div>
            <div>
              <div className="container-prod-list-card-reflesh-circle">
                <img src={"/icons/v2/icon-reflesh-x3.png"} alt="logo" />
              </div>
            </div>
            <div>
              <div className="reflesh-preferences-card">
                REFRESH
                <img src={"/icons/v2/icon-reflesh-white.png"} alt="logo" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={` ${isOpenPreferences ? "margin-bottom-fixed" : ""}`}>
        <div className="ellipsis-indicator">
          {filterProd.length > 0 &&
            filterProd
              .slice(0, 4)
              .map((_, i) => (
                <img
                  key={i}
                  src={`/icons/v2/ellipse-${
                    productScrollPosition === i ? "in" : "out"
                  }.png`}
                  alt="icon-ellipse"
                />
              ))}
        </div>
      </div>
      {onOpenModal && (
        <DialogProduct
          onOpen={onOpenModal}
          setOnOpen={setOnOpenModal}
          product={product}
        />
      )}
    </>
  );
};
