import { useRef, useState } from "react";
import { Toast } from "primereact/toast";
// import { FileUpload } from "primereact/fileupload";
// import useAuth from "src/app/shared/hooks/useAuth";
import { HeaderHome } from "src/app/shared/components/header-home";
import { OpenPreferences } from "src/app/shared/components/open-preferences";
import { BannerProdRecom } from "./components/banner-prod-recd";
import { PassportPage } from "../passport";
import { PreferredDispensary } from "./components/preferred-dispensary";
import { ProductsHeaderRecommendation } from "./components/recommendations";
import { ProductsListRecommendations } from "./components/products-list";
import { BtnFindDispensary } from "./components/find-dispensary";
import "./profileHome.css";

export const ProfileHome = () => {
  const toast = useRef(null);
  const [isOpenPreferences, setIsOpenPreferences] = useState(false);
  const [isReflesh, setIsReflesh] = useState(false);

  return (
    <>
      <Toast ref={toast} />
      <div className="thcp-profile-home-container">
        <HeaderHome />
        <OpenPreferences
          open={isOpenPreferences}
          setOpen={setIsOpenPreferences}
        />
        {isOpenPreferences && <PassportPage />}
        <BannerProdRecom />
        <PreferredDispensary />
        <ProductsHeaderRecommendation setIsReflesh={setIsReflesh}/>
        <ProductsListRecommendations isOpenPreferences={isOpenPreferences} isReflesh={isReflesh} setIsReflesh={setIsReflesh}/>
        <BtnFindDispensary isOpenPreferences={isOpenPreferences} />
      </div>
    </>
  );
};
