export const mapPreferences = (cannabisPreferences, user) => {
  const data = cannabisPreferences.map((cp, index) => ({
    ...cp,
    optionsSelected:
      index === 1 || index === 2
        ? cp?.options.flatMap((c) =>
            c.items.flatMap((it) =>
              user?.cannabisPreferences?.find((up) => up.id === it.id) !==
              undefined
                ? [it]
                : []
            )
          ) || []
        : cp?.options.filter((c) =>
            user?.cannabisPreferences?.some((up) => up.id === c.id)
          ) || [],
    options: cp.options.map((op) => {
      if (index === 1 || index === 2) {
        return {
          ...op,
          items: op.items.map((it) => ({
            ...it,
            selected: user?.cannabisPreferences?.some((up) => up.id === it.id),
          })),
        };
      } else {
        return {
          ...op,
          selected: user?.cannabisPreferences?.some((up) => up.id === op.id),
        };
      }
    }),
  }));
  return data;
};

export const mapUpdatePreferences = (
  cannabisPreferences,
  { preferenceId, selectedOption },
  step
) => {
  if (step === 1) {
    return cannabisPreferences.map((cp) =>
      cp._id === preferenceId
        ? {
            ...cp,
            optionsSelected: selectedOption,
            options: cp.options.map((op) =>
              op.id === selectedOption.id
                ? { ...op, selected: !op.selected }
                : { ...op, selected: false }
            ),
          }
        : { ...cp }
    );
  } else if (step === 3 || step === 2) {
    return cannabisPreferences.map((cp) =>
      cp._id === preferenceId
        ? {
            ...cp,
            optionsSelected: cp?.optionsSelected.some(
              (c) => c.id === selectedOption.id
            )
              ? cp?.optionsSelected.filter((c) => c.id !== selectedOption.id)
              : cp.optionsSelected.concat({
                  id: selectedOption.id,
                  name: selectedOption.name,
                }),

            options: cp.options.map((op) => ({
              ...op,
              items: op.items.map((it) => {
                if (Array.isArray(selectedOption)) {
                  const isSelected = selectedOption.some(
                    (slOpt) => slOpt.id === it.id
                  );
                  return {
                    ...it,
                    selected: isSelected ? !it.selected : it.selected,
                  };
                } else {
                  return it.id === selectedOption.id
                    ? { ...it, selected: !it.selected }
                    : { ...it };
                }
              }),
            })),
          }
        : { ...cp }
    );
  } else {
    return cannabisPreferences.map((cp) =>
      cp._id === preferenceId
        ? {
            ...cp,
            optionsSelected: cp?.optionsSelected.some(
              (c) => c.id === selectedOption.id
            )
              ? cp?.optionsSelected.filter((c) => c.id !== selectedOption.id)
              : cp.optionsSelected.concat(selectedOption),
            options: cp.options.map((op) =>
              op.id === selectedOption.id
                ? { ...op, selected: !op.selected }
                : { ...op }
            ),
          }
        : { ...cp }
    );
  }
};
