import CannabisDispensariesCard from "../dispensaries-card";
import "./dispensaries-carousel.css";
export const DispensariesCarousel = ({ dispensaries }) => {
  return (
    <div className="thcp-dispensaries-result-container">
      {dispensaries.length > 0 &&
        dispensaries.map((dispensary) => (
          <CannabisDispensariesCard
            key={dispensary._id}
            dispensary={dispensary}
          />
        ))}
    </div>
  );
};
