import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import CannabisDispensaries from "../modules/cannabis-dispensaries";
import {
  DispensariesDashboard,
  DispensariesSignIn,
  DispensariesSignUp,
} from "../modules/dispensaries";
import ProfilePage from "../modules/profile/layout";
import CannabisPreferencesPage from "../modules/profile/preferences";
import useAuth from "../shared/hooks/useAuth";
import PrecheckHistoryPage from "../modules/profile/precheck-history";
import PersonalInfoPage from "../modules/profile/personal-info";
import ChangePasswordPage from "../modules/profile/change-password";
import ContactUsPage from "../modules/profile/contact-us";
import { getCookie } from "../shared/utils/cookies.utils";
import { COOKIES } from "../constants/cookies.constants";
import { useDispatch } from "react-redux";
import { setAppType } from "src/store/slices/ui/UiSlice";
import { AppType } from "../shared/hooks/AppType";
import { PassportPage } from "../modules/profile/passport";
import { ForgotPassword } from "../modules/forgot-password";
import { ChangeDispensary } from "../modules/change-dispensaries";
import { Settings } from "../modules/profile/settings";
import { Dispensary } from "../modules/cannabis-dispensaries/pages/Dispensary";
import { CannabisDispensariesLayout } from "../modules/cannabis-dispensaries/layouts/CannabisDispensariesLayout";

const ProtectedRoute = (props) => {
  const {
    redirectPath: redirectPathProp = "/",
    allowed: allowedProp = [],
    dispatch,
  } = props;

  const allowed = typeof allowedProp === "string" ? [allowedProp] : allowedProp;
  dispatch(setAppType(allowed[0]));

  const subPath = allowed[0] === "thcp_dispensary" ? "dispensary" : "user";
  const { user } = useAuth();
  const location = useLocation();
  const appType = AppType();

  const respCokies = getCookie(
    appType === "dispensary" ? COOKIES.ACCESS_TOKEN_DISP : COOKIES.ACCESS_TOKEN
  );

  const isAccessible =
    user && allowed.length && allowed.some((rol) => user.rol === rol);

  return isAccessible ? (
    <Outlet />
  ) : respCokies ? (
    <Navigate to={`/${subPath}`} state={{ from: location }} replace />
  ) : allowed[0] === "thcp_client" ? (
    window.location.replace("https://www.thcprecheck.com/log-in")
  ) : (
    allowed[0] === "thcp_dispensary" && (
      <Navigate to={redirectPathProp} state={{ from: location }} replace />
    )
  );
};

const AppRoutes = () => {
  const dispatch = useDispatch();

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            allowed="thcp_dispensary"
            redirectPath="/dispensary/signin"
            dispatch={dispatch}
          />
        }
      >
        <Route
          path="/dispensary/dashboard"
          element={<DispensariesDashboard />}
        />
      </Route>
      <Route
        element={<ProtectedRoute allowed="thcp_client" dispatch={dispatch} />}
      >
        <Route path="/user/profile" element={<ProfilePage />} />
        <Route path="/user/profile/passport" element={<PassportPage />} />
        <Route
          path="/user/profile/cannabis-preferences"
          element={<CannabisPreferencesPage />}
        />
        <Route
          path="/user/profile/change-dispensaries"
          element={<ChangeDispensary />}
        />
        <Route path="/user/profile/settings" element={<Settings />} />
        <Route
          path="/user/profile/settings/personal-info"
          element={<PersonalInfoPage />}
        />
        <Route
          path="/user/profile/settings/precheck-history"
          element={<PrecheckHistoryPage />}
        />
        <Route
          path="/user/profile/settings/change-password"
          element={<ChangePasswordPage />}
        />
        <Route
          path="/user/profile/settings/contact-us"
          element={<ContactUsPage />}
        />
        {/* <Route path="/user/profile/settings/user-id" element={<UserIdPage />} /> */}

        <Route
          path="/search-dispensaries"
          element={<CannabisDispensariesLayout />}
        >
          <Route index element={<CannabisDispensaries />} />
          <Route path="/search-dispensaries/:id" element={<Dispensary />} />
        </Route>
      </Route>

      <Route path="/dispensary/signin" element={<DispensariesSignIn />} />
      <Route path="/dispensary/signup" element={<DispensariesSignUp />} />
      <Route path="/password/reset" element={<ForgotPassword />} />
    </Routes>
  );
};

export default AppRoutes;
