import React from "react";
import "./navBar.component.css";
import { Link } from "react-router-dom";

const NavBarComponent = () => {
  return (
    <nav className="thcp-nav">
      <div className="thcp-nav-container">
        <Link to="/user/profile" className="thcp-nav-container-title">
          <p className="thcp-nav-passport">Passport</p>
        </Link>
        <div className="thcp-nav-container-icon">
          <Link to="/user/profile/settings">
            <img
              src="/icons/v2/setting.png"
              alt="THCP logo"
              className="thcp-nav-icon-settings"
            />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBarComponent;
