import { decryptData } from "../helpers/encrypt";
import authClient from "../utils/auth-cookie-client.utils";

export class PassportService {
  async getPassport(userId, appType) {
    try {
      const { data } = await authClient(appType).get("/passport/" + userId);
      const dataDecrypt = decryptData(data?.payload);
      return [null, dataDecrypt];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
}
