import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appType: "",
};

export const UiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setAppType: (state, { payload }) => {
      state.appType = payload;
    },
  },
});

export const { setAppType } = UiSlice.actions;

export const ui = (state) => state.ui;
