import React from "react";
import "./recommedations.css";
import { titlesHome } from "src/app/constants/passport.constants";
import { Link } from "react-router-dom";

export const ProductsHeaderRecommendation = ({ setIsReflesh }) => {
  return (
    <div className="product-recom-container">
      <div className="product-container-header">
        <div className="preferred-container-header">
          <img src={"/icons/v2/icon-preferred.png"} alt="logo" />
          <p>{titlesHome.TITLE2}</p>
        </div>
        <div className="container-btn">
          <Link
            // TODO: Se debe agregar accion del to
            to="#"
            className="reflesh-preferences"
            onClick={() => setIsReflesh(true)}
          >
            REFRESH
            <img src={"/icons/v2/icon-reflesh.png"} alt="logo" />
          </Link>
        </div>
      </div>
    </div>
  );
};
