import authClient from "../utils/auth-cookie-client.utils";

export class ProductService {
  async getProductRec(dispensaryProduct, appType) {
    try {
      const { data } = await authClient(appType).get(
        "/products?dispensary=" + dispensaryProduct
      );
      return [null, data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
}
