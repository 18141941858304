import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { PrecheckService } from "../../../../../../shared/services/precheck.service";
import { Button } from "primereact/button";

import TotalPrechecks from "../../report/total-prechecks";

import "./upcoming-precheck.css";
import { AppType } from "src/app/shared/hooks/AppType";
import { createEventGA } from "src/app/shared/helpers/analytics";

const UpcomingPrecheck = ({ setActiveDialog, setSelectedProfile, toast }) => {
  const [products, setProducts] = useState([]);
  const [disabled, setDisabled] = useState({});
  const [reload, setReload] = useState(0);
  const appType = AppType();

  const precheckService = new PrecheckService();

  const handleViewProfile = (rowData) => () => {
    setActiveDialog(true);
    createEventGA(
      "Dispensary",
      "view_passport_dispensary",
      "View Passport dispensary"
    );
    setSelectedProfile(rowData);
  };

  const handleCheckin = (rowData) => async () => {
    const [err] = await precheckService.updateStatus(
      "check-in",
      rowData._id,
      appType
    );
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error check-in",
        life: 1500,
      });
      return;
    }
    setProducts(products.filter((p) => p._id !== rowData._id));
    createEventGA(
      "Dispensary",
      "check_in_dispensary",
      "Check in success dispensary"
    );
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Check-in successfully",
      life: 1500,
    });
  };

  const handleRemider = (rowData) => async () => {
    const payload = {
      precheckId: rowData._id,
      user: { id: rowData.user.username },
    };
    const [err] = await precheckService.sendReminderToClient(payload, appType);
    if (err && err.response.status !== 400) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err.message,
        life: 1500,
      });
      return;
    }
    if (err && err.response.status === 400) {
      toast.current.show({
        severity: "error",
        summary: err.response.data.message,
        detail: err.message,
        life: 1500,
      });
      return;
    }
    setDisabled({ ...disabled, [rowData._id]: true });
    createEventGA(
      "Dispensary",
      "reminder_dispensary",
      "Reminder success dispensary"
    );
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Reminder sent successfully",
      life: 1500,
    });
  };

  const handleNoShow = (rowData) => async () => {
    const [err] = await precheckService.updateStatus(
      "no-show",
      rowData._id,
      appType
    );
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Already setted to No Show",
        life: 1500,
      });
      return;
    }
    setProducts(products.filter((p) => p._id !== rowData._id));
    createEventGA("Dispensary", "no_show_dispensary", "No show dispensary");
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Precheck set to No Show",
      life: 1500,
    });
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <div className="thcp-button-container" style={{ minWidth: "28rem" }}>
        <Button
          className="thcp-button-checkin"
          label="Check-in"
          onClick={handleCheckin(rowData)}
        />
        <Button
          className={
            disabled[rowData._id] || rowData.isReminderSent
              ? "thcp-button-reminder disabled"
              : "thcp-button-reminder"
          }
          label="Reminder"
          onClick={handleRemider(rowData)}
          disabled={disabled[rowData._id] || rowData.isReminderSent}
        />
        <Button
          className="thcp-button-noshow"
          label="No show"
          onClick={handleNoShow(rowData)}
        />
        <Button
          className="thcp-button-profile"
          label="View Passport"
          onClick={handleViewProfile(rowData)}
          text
        />
      </div>
    );
  };

  const handleRefreshList = () => {
    setReload(reload + 1);
  };

  const clientNameTemplate = (rowData) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        className="thcp-table-profile-img"
        src={rowData.customerProfileImg || "/imgs/default-user-pic.png"}
        alt="user_img"
      />
      <div>
        <span>{rowData.fullName}</span>
        {rowData.isReminderSent &&
          (rowData.status === "prechecked" ? (
            <span className="thcp-span-reminder reminder-orange">
              Reminder sent
            </span>
          ) : (
            <span className="thcp-span-reminder reminder-green">
              Reminder confirmed
            </span>
          ))}
      </div>
    </div>
  );

  useEffect(() => {
    precheckService
      .getUpcoming(appType)
      .then(([_, data]) => setProducts(data))
      .catch(([err]) => console.error(err));
    const intervalId = setInterval(() => {
      precheckService
        .getUpcoming(appType)
        .then(([_, data]) => setProducts(data))
        .catch(([err]) => console.error(err));
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="thcp-precheck-container">
      <TotalPrechecks reload={reload} />
      <div className="thcp-precheck-table-container">
        <div className="thcp-precheck-table-header">
          <h2>Appointment Manager</h2>
          <i
            className="pi pi-refresh"
            style={{ fontSize: "1.5rem" }}
            onClick={handleRefreshList}
          ></i>
        </div>
        <div className="card-content">
          <DataTable value={products} className="thcp-precheck-table">
            <Column
              className="thcp-first"
              field="fullName"
              header="Client"
              body={clientNameTemplate}
            />
            <Column
              style={{ minWidth: "8rem" }}
              field="timeOfPrecheck"
              header="Received"
            />
            <Column
              style={{ minWidth: "8rem" }}
              field="serviceType"
              header="Service"
            />
            <Column
              style={{ minWidth: "9rem" }}
              field="timeFrame"
              header="Arrival"
            />
            <Column
              style={{ minWidth: "10rem" }}
              field="date"
              header="Date"
              body={(rowData) =>
                rowData.date ? new Date(rowData.date).toDateString() : "No date"
              }
            />
            <Column
              field="quantity"
              header="Selection"
              body={statusBodyTemplate}
            />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default UpcomingPrecheck;
