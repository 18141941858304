export const passport = {
  TITLE1: "Passport",
  TITLE2: "Cannabis Level",
  TITLE3: "Activity",
  TITLE4: "Method",
  TITLE5: "Preferred Cannabis Type",
  TITLE6: "Consumption Recommendations",
  TITLE7: "Avoid",
};

export const titlesHome = {
  TITLE1: "Based on your preferred dispensary",
  TITLE2: "Your Recommendations"
}