import { Card } from "primereact/card";
import { passport } from "src/app/constants/passport.constants";

export const ConsumptionTemplate = ({ listConsumption }) => {
  const style = {
    passportCard: {
      borderRadius: "12px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      fontFamily: '"Poppins", sans-serif',
      textAlign: "center",
      alignItems: "center",
      //OTHER
      height: "100%",
      width: "250px",
      display: "flex",
    },
    titleCard: {
      color: "#36b37e",
      fontSize: "1.1rem",
      fontWeight: 700,
    },
    listItems: {
      display: "flex",
      marginBottom: "0.5rem",
    },
    listType: {
      width: "45%",
      display: "flex",
      // flexDirection: "column",
      alignItems: "flex-end",
      marginBottom: "0.3rem",
    },
    itemType: {
      background: "rgba(135, 240, 246, 0.5)",
      borderRadius: "12px",
      width: "max-content",
      padding: "0 1rem",
      height: "22px",
      fontSize: "14px",
      fontWeight: "600",
      color: "#696c72",
      display: "flex",
      alignItems: "center",
    },
    arrow: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "rgba(0, 0, 0, 0.5)",
      fontSize: "1rem",
      gap: "1.7rem",
    },
    listResult: {
      width: "45%",
      display: "flex",
      flexDirection: "column",
      gap: " 0.5rem",
    },
    itemResults: {
      background: "rgba(251, 145, 196, 0.5)",
      borderRadius: "12px",
      width: "25rem",
      padding: "0 0.5rem",
      height: "22px",
      fontSize: "12px",
      fontWeight: "600",
      color: "#696c72",
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <td>
      <Card
        style={style.passportCard}
        className="custom-card"
        title={
          <div style={{ width: "45%" }}>
            <span style={{ ...style.titleCard }}>{passport.TITLE6}</span>
          </div>
        }
      >
        {/* <div style={style.listItems}> */}
        <table>
          {listConsumption &&
            Object.keys(listConsumption).map((item, i) => (
              <tr style={style.listType} key={i}>
                <td style={style.itemType}>{item.replace(/s$/, "")}</td>
                <td style={style.itemResults}>{listConsumption[item]}</td>
              </tr>
            ))}
        </table>
      </Card>
    </td>
  );
};
