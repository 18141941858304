export const filterPastTimes = (timeSlots) => {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  const nextHalfHour = currentMinute >= 30 ? currentHour + 1 : currentHour;
  const nextValidTime = currentMinute >= 30 ? "00" : "30";
  const nextTimeSlot = `${nextHalfHour % 24}:${nextValidTime}`;

  const formattedNextTimeSlot = new Date(
    now.toDateString() + " " + nextTimeSlot
  );

  return timeSlots.filter((slot) => {
    const slotDate = new Date(now.toDateString() + " " + slot);
    return slotDate >= formattedNextTimeSlot;
  });
};
