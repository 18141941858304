import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "primereact/carousel";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";

import { PrecheckService, PreferenceService } from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";

import "./precheck-queue.css";
import { PassportService } from "src/app/shared/services/passport.service";
import { Header } from "src/app/modules/profile/passport/components/header/Header";
import { Activity } from "src/app/modules/profile/passport/components/activity/Activity";
import { Method } from "src/app/modules/profile/passport/components/method/Method";
import { CannabisType } from "src/app/modules/profile/passport/components/type/CannabisType";
import { Consumption } from "src/app/modules/profile/passport/components/consumption/Consumption";
import { Avoid } from "src/app/modules/profile/passport/components/avoid/Avoid";
import { encryptedData } from "src/app/shared/helpers/encrypt";
import { createEventGA } from "src/app/shared/helpers/analytics";

const PrecheckQueue = ({ selectedClient, setSelectedClient }) => {
  const toast = useRef(null);
  // const [cannabisPreferences, setCannabisPreferences] = useState([]);
  const [products, setProducts] = useState([]);
  const [notas, setNotas] = useState({});
  const [productsAmount, setProductsAmount] = useState(0);
  const [passport, setPassport] = useState([]);

  const appType = AppType();

  const precheckService = new PrecheckService();

  const dataPersonal = {
    name: selectedClient?.user?.name,
    lastName: selectedClient?.user?.lastName,
    img: selectedClient?.customerProfileImg,
  };

  const responsiveOptions = [
    {
      breakpoint: "1199px",
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: "991px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const getIcon = (selector) => {
    const personalIcon = [
      "enjoy-preference-text",
      "experience-preference-text",
    ];
    const badIcon = ["allergen-preference-text"];

    switch (true) {
      case personalIcon.some((p) => p === selector):
        return (
          <span className="preference-icon blue">
            <i className="bi bi-emoji-smile"></i>
          </span>
        );
      case badIcon.some((p) => p === selector):
        return (
          <span className="preference-icon red">
            <i className="bi bi-emoji-frown"></i>
          </span>
        );
      default:
        return (
          <span className="preference-icon green">
            <i className="bi bi-journal-plus"></i>
          </span>
        );
    }
  };

  const accept = (rowData) => async () => {
    if (selectedClient) {
      toast.current.show({
        severity: "warning",
        summary: "Warning",
        detail: "Close out the current ",
        life: 1500,
      });
      return;
    }
    setProducts(products.filter((p) => p._id !== rowData._id));
    setSelectedClient(rowData);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Check-in successfully",
      life: 1500,
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirmDonePrecheck = (rowData) => () => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: accept(rowData),
      reject,
    });
    createEventGA(
      "Dispensary",
      "attend_process_dispensary",
      "attend process user dispensary"
    );
  };

  const closeOut = (rowData) => async () => {
    const [err] = await precheckService.updateStatus(
      "done",
      selectedClient._id,
      appType
    );
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error closing out",
        life: 1500,
      });
      return;
    }
    setSelectedClient(null);
  };

  const confirmCloseOut = (rowData) => () => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: closeOut(rowData),
      reject,
    });
  };

  const handleNotesChange = (e) => {
    setNotas({ ...notas, [selectedClient._id]: e.target.value });
  };

  const clientTemplate = (clientData) => {
    return (
      <div className="thcp-carousel-queue">
        <div>
          <img
            className="thcp-carousel-profile-img"
            src={clientData.customerProfileImg || "/imgs/default-user-pic.png"}
            alt="user_img"
          />
        </div>
        <div className="thcp-carousel-queue-item">
          <h5>{`${clientData.user?.name} ${clientData.user?.lastName}`}</h5>
          <h6>
            Verified{" "}
            <img
              style={{ width: "15px" }}
              src="/icons/thcp-verified.png"
              alt="logo"
            />
          </h6>
          <Button
            className="thcp-button-queue bg-green"
            label="Attend"
            onClick={confirmDonePrecheck(clientData)}
          />
        </div>
      </div>
    );
  };

  const updatePrecheck = async () => {
    if (notas[selectedClient._id]) {
      const payload = {
        precheckId: selectedClient._id,
        notes: notas[selectedClient._id],
        productsAmount: productsAmount,
      };
      const dataEncrypt = encryptedData(payload);
      const [err] = await precheckService.updatePrecheck(
        { data: dataEncrypt },
        appType
      );
      if (err) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error saving notes",
        });
      }
      createEventGA(
        "Dispensary",
        "add_notes_attend_dispensary",
        "Add notes attend user dispensary"
      );
    }

    confirmCloseOut()();
  };

  const filterProducts = (data) => {
    const filter = data.filter((d) => d._id !== selectedClient?._id);
    setProducts(filter);
  };

  useEffect(() => {
    if (selectedClient?.user) {
      const getUserPassport = async () => {
        const passportServices = new PassportService();
        const [_, passRes] = await passportServices.getPassport(
          selectedClient?.user?.username,
          appType
        );
        _ ? setPassport([]) : setPassport(passRes);
      };
      getUserPassport();
    }

    precheckService
      .getQueue(appType)
      .then(([_, data]) => filterProducts(data))
      .catch(([err]) => console.error(err));

    const intervalId = setInterval(() => {
      precheckService
        .getQueue(appType)
        .then(([_, data]) => filterProducts(data))
        .catch(([err]) => console.error(err));
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedClient]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="thcp-precheck-container">
      <Toast ref={toast} />
      <div className="thcp-precheck-queue-content">
        <h2>Up Next</h2>
        <div className="card-content">
          <Carousel
            value={products}
            numScroll={1}
            numVisible={3}
            responsiveOptions={responsiveOptions}
            itemTemplate={clientTemplate}
          />
        </div>
      </div>
      {selectedClient && (
        <div className="thcp-precheck-queue-content">
          <h2>Current Customer</h2>
          <div className="thcp-queue-gap" style={{ display: "flex" }}>
            <div className="thcp-precheck-current-client">
              <Header level={passport?.level} data={dataPersonal} />
              <div className="thcp-profile-passport-container container-dispensary">
                <div className="thcp-profile-passport-first">
                  <Activity listActivity={passport?.activity} />
                  <Method listMethod={passport?.method} />
                </div>
                <CannabisType listType={passport?.cannabis_type} />
                <div className="thcp-profile-passport-first">
                  <Consumption listConsumption={passport?.consumption} />
                  <Avoid listAvoid={passport?.avoid} />
                </div>
              </div>
            </div>
            <div>
              <div className="thcp-precheck-current-notes">
                <h5>
                  <img
                    style={{ width: "15px" }}
                    src="/icons/thcp-verified.png"
                    alt="logo"
                  />{" "}
                  {`${selectedClient.user?.name}'s notes`}
                </h5>
                <div className="thcp-notes">
                  <ProductsAmount
                    amount={productsAmount}
                    setAmount={setProductsAmount}
                  />
                  <InputTextarea
                    autoResize
                    value={notas[selectedClient._id] || ""}
                    onChange={handleNotesChange}
                    rows={10}
                    style={{
                      width: "100%",
                      fontSize: "1.6rem",
                      padding: "1rem 2rem",
                    }}
                    placeholder="Notes ..."
                    className="thcp-input-textarea-queue"
                  />
                </div>
              </div>
              <div style={{ textAlign: "center", marginTop: "3rem" }}>
                <Button
                  className="thcp-button-queue bg-orange"
                  label="Close Out"
                  onClick={updatePrecheck}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfirmDialog />
    </div>
  );
};

const ProductsAmount = ({ amount, setAmount }) => {
  return (
    <>
      <p className="thcp-products-amount-label">Products Purchased</p>
      <div className="thcp-products-amount-container">
        {Array.from(Array(5)).map((_, index) => (
          <button
            onClick={() => {
              setAmount(index);
            }}
            className={`thcp-product-amount-button ${
              amount === index && "selected"
            }`}
            key={index}
          >
            {index}
          </button>
        ))}
      </div>
    </>
  );
};

export default PrecheckQueue;
