import React, { useEffect, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { PrecheckService } from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";

import "./total-prechecks.css";

const TotalPrechecks = ({ reload }) => {
  const appType = AppType();

  const [totalReports, setTotalReports] = useState({
    totalPrechecks: 0,
    totalPrechecksToday: 0,
    totalConfirmed: 0,
    totalConfirmedToday: 0,
  });

  const precheckService = new PrecheckService();

  useEffect(() => {
    precheckService
      .getTotalPrecheckReport(appType)
      .then(([err, data]) => !err && setTotalReports(data))
      .catch((err) => console.error(err));
    const intervalId = setInterval(() => {
      precheckService
        .getTotalPrecheckReport(appType)
        .then(([err, data]) => !err && setTotalReports(data))
        .catch((err) => console.error(err));
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="thcp-report-container">
      <div className="thcp-report-card">
        <h2>THC Precheck's Received</h2>
        <div className="thcp-report-card-info">
          <div className="thcp-separator">
            <ProgressBar
              value={totalReports.totalPrechecksToday || 1}
              className="thcp-progress-bar orange"
            ></ProgressBar>
            <h4>Today</h4>
            <h1>{totalReports.totalPrechecksToday || "-"}</h1>
          </div>
          <div>
            <ProgressBar
              value={totalReports.totalPrechecks || 1}
              className="thcp-progress-bar green"
            ></ProgressBar>
            <h4>Total YTD</h4>
            <h1>{totalReports.totalPrechecks || "-"}</h1>
          </div>
        </div>
      </div>
      <div className="thcp-report-card">
        <h2>Confirmed Check-In's</h2>
        <div className="thcp-report-card-info">
          <div className="thcp-separator">
            <ProgressBar
              value={totalReports.totalConfirmedToday || 1}
              className="thcp-progress-bar orange"
            ></ProgressBar>
            <h4>Today</h4>
            <h1>{totalReports.totalConfirmedToday || "-"}</h1>
          </div>
          <div>
            <ProgressBar
              value={totalReports.totalConfirmed || 1}
              className="thcp-progress-bar green"
            ></ProgressBar>
            <h4>Total YTD</h4>
            <h1>{totalReports.totalConfirmed || "-"}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalPrechecks;
