import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import useAuth from "src/app/shared/hooks/useAuth";
import { AuthService } from "src/app/shared/services";
import { setCookie } from "src/app/shared/utils/cookies.utils";
import { Toast } from "primereact/toast";
import { createEventGA } from "src/app/shared/helpers/analytics";
import "./signin-form.css";

const DispensariesSigninForm = () => {
  const { setUser } = useAuth();
  const toast = useRef(null);

  const [submittedOne, setSubmittedOne] = useState(false);
  const [isViewPass, setIsViewPass] = useState(false);
  const [stepTwoData, setStepTwoData] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dispensary/dashboard";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmittedOne(true);
    if (!stepTwoData.password || !stepTwoData.username) {
      toast.current.show({
        severity: "warn",
        summary: "Error",
        detail: "Username and password are required",
        life: 1500,
      });
      return;
    }
    const authService = new AuthService();
    const [error, signUpRes] = await authService.dispensarySignIn({
      ...stepTwoData,
    });
    if (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Invalid credentials",
        life: 1500,
      });
      return;
    }

    setCookie("thcp-access-dispensary", signUpRes.token);
    createEventGA("Dispensary", "login_dispensary", "Log-in dispensary");
    setUser({
      token: signUpRes.token,
      rol: signUpRes.rol,
      username: signUpRes.username,
      userId: signUpRes.id,
    });
    localStorage.setItem(
      "thcp_user",
      JSON.stringify({
        token: signUpRes.token,
        rol: signUpRes.rol,
        username: signUpRes.username,
      })
    );
    navigate(from, { replace: true });
  };

  const onStepTwoChange = (field) => (e) => {
    const value = e.target.value;
    setStepTwoData({ ...stepTwoData, [field]: value });
  };

  return (
    <div className="thcp-dispensary-signin-container">
      <Toast ref={toast} />
      <h2 className="thcp-form-title">Log In</h2>
      <div className="thcp-signin-form-check">
        <h5>New user?</h5>
        <Link to="/dispensary/signup" className="thcp-link-sign">
          Create an account
        </Link>
      </div>
      <form className="thcp-dispensary-signin-form">
        <span className="p-input-icon-left">
          <i className="pi pi-user" />
          <InputText
            placeholder=""
            value={stepTwoData.username}
            onChange={onStepTwoChange("username")}
            className={submittedOne && !stepTwoData.username && "p-invalid"}
          />
          <span className="thcp-float-label">Username</span>
        </span>
        <span className="p-input-icon-left">
          <i
            className={`pi
                ${isViewPass ? "pi-eye-slash" : "pi-eye"}
                icons-view-pass`}
            onClick={() => setIsViewPass(!isViewPass)}
          />
          <InputText
            placeholder=""
            type={!isViewPass ? "password" : null}
            value={stepTwoData.password}
            onChange={onStepTwoChange("password")}
            className={
              submittedOne && !stepTwoData.password
                ? "thcp-input-password p-invalid"
                : "thcp-input-password"
            }
          />
          <span className="thcp-float-label">Password</span>
        </span>
        <div>
          <Button
            onClick={handleSubmit}
            label="Next"
            icon="pi pi-angle-right"
            iconPos="right"
            className="thcp-form-button"
          />
        </div>
      </form>
    </div>
  );
};

export default DispensariesSigninForm;
