import { InputText } from "primereact/inputtext";
import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import "./general.css";
import { Button } from "primereact/button";
import { CannabisDispensariesService } from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";
import { Toast } from "primereact/toast";
import { encryptedData } from "src/app/shared/helpers/encrypt";
import { createEventGA } from "src/app/shared/helpers/analytics";

export const General = ({ data, cities, setIsChange }) => {
  const appType = AppType();
  const dispensaryService = new CannabisDispensariesService();
  const initialDataRef = useRef(data);
  const toast = useRef(null);

  const [dataForm, setDataForm] = useState({
    location: data?.location || "",
    state: data?.state || "",
    zipCode: data?.zipCode || "",
    phoneNumber: data?.phoneNumber || "",
    website: data?.website || "",
    retailNumber: data?.retailNumber || "",
  });
  const [selectedCity, setSelectedCity] = useState(data?.state?.code);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);

  const onChangeDataForm = (field) => (e) => {
    const value = e.target.value;
    setDataForm({ ...dataForm, [field]: value });
  };

  const handleDropdownChange = (field) => (e) => {
    const value = e.value;
    const selectedCity = cities.find((c) => c.code === value);
    setSelectedCity(value);
    setDataForm({ ...dataForm, [field]: selectedCity });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSaveButtonActive(false);
    const dataEncrypt = encryptedData({ general: dataForm });
    const [disErr] = await dispensaryService.update(
      { data: dataEncrypt },
      appType
    );
    if (disErr) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating information",
      });
      createEventGA(
        "Dispensary",
        "update_settings_general_error_dispensary",
        "Update Settings in tab general Error dispensary"
      );
      setIsSaveButtonActive(true);
      return;
    }
    createEventGA(
      "Dispensary",
      "update_settings_general_success_dispensary",
      "Update Settings in tab general dispensary"
    );
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Data updated successfully",
    });
    setIsChange(true);
  };

  useEffect(() => {
    const isDataChanged = Object.keys(dataForm).some(
      (key) => dataForm[key] !== initialDataRef.current[key]
    );
    setIsSaveButtonActive(isDataChanged);
  }, [dataForm]);

  return (
    <div>
      <Toast ref={toast}></Toast>

      <form className="thcp-dispensary-signup-form">
        <span className="p-input-icon-left">
          <InputText
            value={data?.username}
            className={"field-disabled"}
            disabled
          />
          <i className="pi pi-user" />
          <span className="thcp-float-label">Username</span>
        </span>
        <span className="p-input-icon-left">
          <InputText
            type="text"
            value={dataForm.location}
            onChange={onChangeDataForm("location")}
          />
          <span className="thcp-float-label">Address</span>
        </span>
        <span className="p-input-icon-left">
          <Dropdown
            value={selectedCity}
            onChange={handleDropdownChange("state")}
            options={cities}
            optionLabel="name"
            optionValue="code"
            placeholder="Select a City"
            className="w-full md:w-14rem"
          />
          <span className="thcp-float-label">State</span>
        </span>
        <span className="p-input-icon-left">
          <InputText
            type="text"
            value={dataForm.zipCode || ""}
            onChange={onChangeDataForm("zipCode")}
            placeholder="00000"
          />
          <span className="thcp-float-label">ZIP Code</span>
        </span>
        <span className="p-input-icon-left">
          <InputText
            value={dataForm.phoneNumber}
            onChange={onChangeDataForm("phoneNumber")}
            placeholder="000-000-0000"
            // className={submitted && !stepTwoData.phoneNumber && "p-invalid"}
          />
          <i className="pi pi-phone" />
          <span className="thcp-float-label">Phone Number</span>
        </span>
        <span className="p-input-icon-left">
          <InputText
            value={dataForm.website}
            onChange={onChangeDataForm("website")}
          />
          <i className="pi pi-globe" />
          <span className="thcp-float-label">Website (optional)</span>
        </span>
        <span className="p-input-icon-left">
          <InputText
            value={dataForm.retailNumber}
            onChange={onChangeDataForm("retailNumber")}
          />
          <span className="thcp-float-label">Retail Licence # (optional)</span>
        </span>
        <div
          className={`thcp-action-button ${
            !isSaveButtonActive ? "p-disabled" : ""
          }`}
        >
          <Button
            onClick={handleUpdate}
            label="Save"
            className="thcp-form-button-submit"
          />
        </div>
      </form>
    </div>
  );
};
