export const validateStepOne = (stepOneData) => {
  if (stepOneData.username && stepOneData.password && stepOneData.password2) {
    return stepOneData.password === stepOneData.password2;
  }
  return false;
};

export const validateStepTwo = (stepTwoData) => {
  if (
    stepTwoData.address &&
    stepTwoData.state &&
    stepTwoData.zipCode &&
    stepTwoData.phoneNumber &&
    stepTwoData.email &&
    stepTwoData.name
  ) {
    return true;
  }
  return false;
};

export const validateSettings = (stepTwoData) => {
  if (
    stepTwoData.location &&
    stepTwoData.state &&
    stepTwoData.zipCode &&
    stepTwoData.phoneNumber &&
    stepTwoData.email
  ) {
    return true;
  }
  return false;
};