import client from "../utils/base-client.utils";

export class StatesService {
  async getStates(payload) {
    try {
      const response = await client.get("/states", payload);
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
}
