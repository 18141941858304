import { passport } from "src/app/constants/passport.constants";
import { Skeleton } from "primereact/skeleton";
import "./cannabisType.css";

export const CannabisType = ({ listType }) => {
  return (
    <div className="passport-item-container">
      <div className="passport-item-container-header">
        <img src={"/icons/v2/icon-preferred.png"} alt="logo" />
        <h3 className="title-item-passport">{passport.TITLE5}</h3>
      </div>
      {listType ? (
        <div className="passport-map-items fade-id">
          {listType?.map((item, i) => (
            <div className="preferred-item" key={i}>
              <p>{item}</p>
            </div>
          ))}
        </div>
      ) : (
        <Skeleton width="100%" height="58px"></Skeleton>
      )}
    </div>
  );
};
