import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./App.css";

import AppRoutes from "./app/router";
import UserProvider from "./context/user/userProvider";
import { store } from "./store/store";
import { LoaderProvider } from "./context/loader/loaderProvider";

const App = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <UserProvider>
          <LoaderProvider>
            <Routes>
              <Route path="/*" element={<AppRoutes />} />
            </Routes>
          </LoaderProvider>
        </UserProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
