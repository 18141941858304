import axios from "axios";
import { COOKIES } from "../../constants/cookies.constants";
import { getCookie } from "./cookies.utils";

const authClient = (appType) => {
  const tokenFromCookie = getCookie(
    appType === "dispensary" ? COOKIES.ACCESS_TOKEN_DISP : COOKIES.ACCESS_TOKEN
  );

  return axios.create({
    baseURL: process.env.REACT_APP_THCP_API_URL,
    headers: {
      "X-Access-Token": tokenFromCookie,
    },
  });
};


export default authClient;
