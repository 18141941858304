import React, { useEffect, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { FileUpload } from "primereact/fileupload";
import { PassportService } from "../../services/passport.service";
import { AppType } from "../../hooks/AppType";
import { getDatePrecheck } from "./getDate";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { createEventGA } from "../../helpers/analytics";
import { Toast } from "primereact/toast";
import { DialogVerify } from "src/app/modules/profile/personal-info/components/dialogVerify/DialogVerify";
import { PrecheckService, UserService } from "../../services";
import "./headerHome.css";

export const HeaderHome = () => {
  const { user, setUser } = useAuth();
  const appType = AppType();
  const toast = useRef(null);

  const [passportLevel, setPassportLevel] = useState([]);
  const [lastPrecheck, setLastPrecheck] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);

  const accept = async () => {
    const precheckService = new PrecheckService();
    const [err] = await precheckService.cancelPrecheckByUser(
      lastPrecheck._id,
      appType
    );
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Already setted to No Show",
        life: 1500,
      });
      return;
    }
    setLastPrecheck({});
    createEventGA("User", "cancel_precheck_user", "Cancel_precheck_user");
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Precheck set to No Show",
      life: 1500,
    });
  };

  const reject = () => {};

  const confirm1 = () => {
    confirmDialog({
      message: "Are you sure you want to cancel your precheck?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
    });
  };

  const handleUploadImage = async (event) => {
    const formData = new FormData();
    const userService = new UserService();
    formData.append("profilePic", event.files[0]);

    const [err, uploadRes] = await userService.uploadProfileImage(
      formData,
      appType
    );
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error uploading image",
        life: 1500,
      });
      event.options.clear();
      return;
    }
    createEventGA("User", "change_img_profile_user", "Change img profile");
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Precheck set to No Show",
      life: 1500,
    });
    event.options.clear();
    setUser({ ...user, profileImg: uploadRes.userImage });
  };

  useEffect(() => {
    const getUserPassport = async () => {
      const passportServices = new PassportService();
      const [_, passRes] = await passportServices.getPassport(
        user.username,
        appType
      );
      _ ? setPassportLevel([]) : setPassportLevel(passRes.level);
    };
    const getPrecheck = async () => {
      const precheckService = new PrecheckService();
      const [_, precheckRes] = await precheckService.getLastUserPrecheck(
        user?.username,
        appType
      );
      _ ? setLastPrecheck({}) : setLastPrecheck(precheckRes);
    };
    getUserPassport();
    getPrecheck();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Toast ref={toast} />
      <div
        className={`thcp-profile-home-header ${
          Object.keys(lastPrecheck).length > 0
            ? "precheckActiveHome"
            : "precheckNoActiveHome"
        }`}
      >
        <div
          className={`thcp-profile-home-header-1 ${
            lastPrecheck ? "precheckActive" : ""
          }`}
        >
          <div className="thcp-profile-img">
            <img
              className="thcp-profile-image"
              src={user.profileImg?.url || "/imgs/default-user-pic.png"}
              alt="user_img"
            />
          </div>
          <div className="thcp-profile-info">
            <div className="thcp-profile-info-name">
              <p className="thcp-profile-info-name-person">{`${
                user?.firstName === "Jhon" ? "" : user?.firstName
              } ${
                user?.lastName === "Doe" ? "" : user?.lastName.split("")[0]
              }.`}</p>
              <div className="thcp-profile-status-verify">
                <p
                  className={`type-verify ${
                    user?.verifiedStatus !== "accept" ? "verify-action" : ""
                  }`}
                  onClick={() => {
                    user?.verifiedStatus !== "accept" && setDialogVisible(true);
                  }}
                >
                  {user?.verifiedStatus === "accept"
                    ? "Verified"
                    : "Not verified"}
                </p>
                <img
                  src={`${
                    user?.verifiedStatus === "accept"
                      ? "/icons/thcp-verified.png"
                      : "/icons/v2/thcp-verified.png"
                  }`}
                  alt="logo"
                />
              </div>
            </div>
            <div className="thcp-profile-info-experience">
              <p className="thcp-profile-info-experience-text">
                Experience Level
              </p>
              <div className="thcp-profile-info-experience-type">
                <p>{passportLevel}</p>
              </div>
            </div>
          </div>
        </div>
        {lastPrecheck._id && (
          <div className="thcp-profile-precheck">
            <div className="thcp-profile-upcoming-precheck">
              <div className="thcp-profile-upcoming-precheck-left">
                <span>Upcoming Appointment</span>
                <h4>{lastPrecheck.dispensary?.name}</h4>
                <div className="thcp-profile-precheck-date">
                  <i
                    className="pi pi-calendar-minus"
                    style={{ fontSize: "2rem", marginRight: "1rem" }}
                  ></i>
                  <h4 className="thcp-user-precheck-date-h4">
                    {getDatePrecheck(lastPrecheck.date)},{" "}
                    <strong>{lastPrecheck.timeFrame}</strong>
                  </h4>
                </div>
              </div>
              <div>
                <Button
                  className="thcp-button"
                  label="Cancel"
                  onClick={confirm1}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <ConfirmDialog />
      {dialogVisible && (
        <DialogVerify
          dialogVisible={dialogVisible}
          setDialogVisible={setDialogVisible}
          path={"profile"}
        />
      )}
    </>
  );
};
