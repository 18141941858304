import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from "primereact/tabview";
import { Toast } from "primereact/toast";

import { PrecheckService } from "src/app/shared/services";

import "./user-profile-dialog.css";
import { AppType } from "src/app/shared/hooks/AppType";
import { PassportService } from "src/app/shared/services/passport.service";
import { Header } from "src/app/modules/profile/passport/components/header/Header";
import { Activity } from "src/app/modules/profile/passport/components/activity/Activity";
import { Method } from "src/app/modules/profile/passport/components/method/Method";
import { CannabisType } from "src/app/modules/profile/passport/components/type/CannabisType";
import { Consumption } from "src/app/modules/profile/passport/components/consumption/Consumption";
import { Avoid } from "src/app/modules/profile/passport/components/avoid/Avoid";

const ProfileDialog = ({ handleOnHide, activeDialog, selectedprofile }) => {
  const appType = AppType();
  const [notas] = useState({});
  const [passport, setPassport] = useState([]);

  const toast = useRef(null);

  const dataPersonal = {
    name: selectedprofile?.user?.name,
    lastName: selectedprofile?.user?.lastName,
    img: selectedprofile?.customerProfileImg,
  };

  useEffect(() => {
    const getUserPassport = async () => {
      const passportServices = new PassportService();
      const [_, passRes] = await passportServices.getPassport(
        selectedprofile?.user?.username,
        appType
      );
      _ ? setPassport([]) : setPassport(passRes);
    };
    getUserPassport();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tab1HeaderTemplate = (options) => {
    return (
      <div
        className="flex align-items-center px-3 p-tabview-nav-link"
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
      >
        Passport
      </div>
    );
  };

  const tab2HeaderTemplate = (options) => {
    return (
      <div
        className="flex align-items-center px-3 p-tabview-nav-link"
        style={{ cursor: "pointer" }}
        onClick={options.onClick}
      >
        <span style={{ width: "2rem" }}>
          <img
            style={{ width: "1.5rem" }}
            src="/icons/thcp-verified.png"
            alt="thc-icons"
          />
        </span>
        Notes
      </div>
    );
  };

  // const handleNotesChange = (e) => {
  //   setNotas({ ...notas, [selectedprofile._id]: e.target.value });
  // };

  return (
    <Dialog
      visible={activeDialog}
      style={{
        maxWidth: "400px",
        maxHeight: "600px",
        minHeight: "400px",
        minWidth: "400px",
      }}
      onHide={handleOnHide}
      selectedprofile={selectedprofile}
      className="thcp-profile-dialog-container"
    >
      <Toast ref={toast} />
      <div className="thcp-profile-dialog-content">
        <TabView>
          <TabPanel headerTemplate={tab1HeaderTemplate}>
            <Header level={passport?.level} data={dataPersonal} />
            <div className="thcp-profile-passport-container container-dispensary">
              <div className="thcp-profile-passport-first">
                <Activity listActivity={passport?.activity} />
                <Method listMethod={passport?.method} />
              </div>
              <CannabisType listType={passport?.cannabis_type} />
              <div className="thcp-profile-passport-first">
                <Consumption listConsumption={passport?.consumption} />
                <Avoid listAvoid={passport?.avoid} />
              </div>
            </div>
          </TabPanel>
          <TabPanel headerTemplate={tab2HeaderTemplate}>
            <div style={{ height: "235px" }}>
              <div className="thcp-dialog-notas">
                <HistoryNotes notes={passport?.notes} />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
      <div className="thcp-profile-dialog-button-container">
        <Button
          label="Close out"
          className="thcp-profile-dialog-button"
          onClick={handleOnHide}
        />
      </div>
    </Dialog>
  );
};

const HistoryNotes = ({ notes }) => {
  if (!notes || notes.length < 1) {
    return <>No notes</>;
  }

  return (
    <table>
      <thead>
        <tr>
          {/* <th>Check-in Date</th> */}
          <th>Note</th>
          <th>Products Purchased</th>
        </tr>
      </thead>
      <tbody>
        {notes?.map((note, index) => (
          <tr key={index}>
            {/* <td>{note.checkInDate}</td> */}
            <td>{note.notes}</td>
            <td>{note.productsAmount}</td>
          </tr>
          // <li key={index}>{note.notes}</li>
        ))}
      </tbody>
    </table>
  );
};

export default ProfileDialog;
