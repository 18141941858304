export const onlyRequiredUserData = (user) => ({
  firstName: user.firstName === "Jhon" ? "" : user.firstName,
  lastName: user.lastName === "Doe" ? "" : user.lastName,
  email: user.email,
  phoneNumber: user.phoneNumber,
  gender: user.gender,
  birthDate: user.birthDate,
});

export const isOfAge = (date) => {
  const currentDate = new Date();
  const differenceYears = currentDate.getFullYear() - date.getFullYear();
  return differenceYears >= 19;
};

export const validateInfo = (data) => {
  const properties = [
    "firstName",
    "lastName",
    "email",
    // "birthDate",
    // "phoneNumber",
  ];

  const dataComplete = properties.every(
    (propertie) =>
      data[propertie] !== undefined &&
      data[propertie] !== null &&
      data[propertie] !== ""
  );

  return dataComplete;
};
