export const getDayOfWeek = (date) => {
  const dayOfWeek = new Date(date).getDay();
  return [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ][dayOfWeek];
};
