import { createContext, useRef, useState } from "react";
import { Budtender } from "./Budtender";
import "./budtenders-list.css";
export const BudtendersListContext = createContext(null);

const BudtenedersListProvider = ({ children }) => {
  const [selectedBudtender, setSelectedBudtender] = useState(null);
  const toast = useRef(null);
  const handleBudtenderSelected = (selected) => {
    setSelectedBudtender((prev) => (prev === selected ? null : selected));
  };
  const getBudtenderFullName = (budtender) => {
    return budtender?.firstName
      ? `${budtender?.firstName} ${budtender?.lastName}`
      : "Any person";
  };
  return (
    <BudtendersListContext.Provider
      value={{
        selectedBudtender,
        handleBudtenderSelected,
        toast,
        getBudtenderFullName,
      }}
    >
      {children}
    </BudtendersListContext.Provider>
  );
};

export const BudtendersList = ({ budtenders }) => {
  return (
    <BudtenedersListProvider>
      <div className="budtenders-list">
        {budtenders?.map((budtender) => (
          <Budtender key={budtender._id} budtender={budtender} />
        ))}
        <Budtender budtender={{}} />
      </div>
    </BudtenedersListProvider>
  );
};
