import React from "react";
import "./index.css"
// import DispensariesSigninForm from "../dispensaries/signin/components/signin-form";
import { ResetPasswordForm } from "./components/reset-form";

export const ForgotPassword = () => {
  return (
    <div className="thcp-sigin-form-container">
      <div className="thcp-sigin-bg-img reset">
        <img src="/imgs/cannabis-white.png" alt="symbol" />
        <div></div>
        {/* <div className="thcp-overlay"></div> */}
      </div>
      <ResetPasswordForm />
    </div>
  );
};
