import React, { useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import "./precheck-dialog.css";
import { useState } from "react";
import { Toast } from "primereact/toast";
import { PrecheckService, UserService } from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";
import { Dropdown } from "primereact/dropdown";
import { createNumberConfirmation, filteredTimes } from "./precheck-dialog";
import { TemplatePrecheck } from "../template-precheck";
import ReactDOMServer from "react-dom/server";
import useAuth from "src/app/shared/hooks/useAuth";
import { PassportService } from "src/app/shared/services/passport.service";
import { encryptedData } from "src/app/shared/helpers/encrypt";
import { createEventGA } from "src/app/shared/helpers/analytics";

const PrecheckDialog = ({
  precheckDialogVisible,
  setPrecheckDialogVisible,
  selectedDispensary,
}) => {
  const { user } = useAuth();
  const appType = AppType();
  const toast = useRef(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [shared, setShared] = useState(0);
  const [passport, setPassport] = useState([]);
  const [loadingPrecheck, setLoadingPrecheck] = useState(false);

  const [isMovil, setIsMovil] = useState(false);
  const [imgQr, setImgQr] = useState(null);
  // const [imageLoaded, setImageLoaded] = useState(false);

  const handleSelect = (e) => {
    if (!selectedTime) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Select a time frame",
        life: 1500,
      });
      return;
    }
    setShared(1);
  };

  const handlePrecheck = async (e) => {
    setLoadingPrecheck(true);
    const precheckService = new PrecheckService();
    const timeFrame = selectedDispensary.reservations.find(
      (r) => r.id === selectedTime
    )?.description;
    const uid = createNumberConfirmation();

    const templateHTML = ReactDOMServer.renderToString(
      <TemplatePrecheck
        passport={passport}
        user={user}
        dispensary={selectedDispensary}
        time={
          selectedDispensary.reservations.find((r) => r.id === selectedTime)
            ?.description
        }
        uid={uid}
      />
    );
    const payload = {
      timeFrame,
      serviceType: "In-store",
      dispensary: {
        id: selectedDispensary?._id,
        name: selectedDispensary?.name,
        companyId: selectedDispensary?.companyId,
      },
      confirmation: uid,
      template: templateHTML,
    };
    const dataEncrypt = encryptedData(payload);
    const [err] = await precheckService.register(
      { data: dataEncrypt },
      appType
    );

    if (!err) {
    }

    if (err && err.response.status !== 403) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error in precheck",
        life: 1500,
      });
      return;
    }
    if (err && err.response.status === 403) {
      setShared(3);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err?.response?.data?.message,
        life: 1500,
      });
      return;
    }
    setLoadingPrecheck(false);
    setShared(2);
    createEventGA("User", "precheck_success_user", "Precheck User Succes");
  };

  const handleForceVerification = async (e) => {
    const userService = new UserService();
    const payload = { path: "profile" };
    const [err, data] = await userService.forceVerification(appType, payload);
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Internal error",
        life: 1500,
      });
      return;
    }
    createEventGA("User", "verify_id_user", "Verify id user");
    if (!isMovil && data?.qr) {
      setShared(4);
      setImgQr(data?.qr);
    } else {
      setPrecheckDialogVisible(false);
      setShared(0);
      window.location.replace(data?.url);
    }
  };

  const handleOnHide = () => {
    setPrecheckDialogVisible(false);
    setSelectedTime(null);
    setShared(0);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobile = /iphone|ipod|android|ie|blackberry|fennec/.test(userAgent);
    setIsMovil(mobile);
  }, []);

  useEffect(() => {
    const getUserPassport = async () => {
      const passportServices = new PassportService();
      const [_, passRes] = await passportServices.getPassport(
        user?.username,
        appType
      );
      _ ? setPassport([]) : setPassport(passRes);
    };
    getUserPassport();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={""}
        visible={precheckDialogVisible}
        style={{ width: "90vw", maxWidth: "350px", minHeight: "150px" }}
        onHide={handleOnHide}
        className="thcp-precheck-dialog-content"
      >
        {shared === 0 && (
          <div className="thcp-precheck-dialog-time-content">
            <h4 className="thcp-precheck-dialog-title">
              Select a time for your visit today
            </h4>
            <Dropdown
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.value)}
              // options={selectedDispensary?.reservations}g
              options={filteredTimes(selectedDispensary?.reservations)}
              optionLabel="description"
              placeholder="Select an Time"
              className="w-full md:w-14rem thcp-time-select"
              optionValue="id"
            />
            <div style={{ width: "100%" }}>
              <Button
                label="Select"
                iconPos="right"
                className="thcp-dialog-button"
                onClick={handleSelect}
              >
                <img alt="thcp-icon" src="icons/cannabis-outline.png" />
              </Button>
            </div>
          </div>
        )}
        {shared === 1 && (
          <div className="thcp-dialog-confirmation">
            <h4 className="thcp-precheck-dialog-title">
              You selected to visit
            </h4>
            <div className="thcp-dialog-confirmation-title">
              <InputText value={selectedDispensary.name} disabled />
            </div>

            <div className="thcp-dialog-share-content">
              <h5>We will actively share your</h5>
              <ul className="thcp-dialog-ul">
                <li>Cannabis preferences</li>
                <li>Verified identity</li>
              </ul>
            </div>

            <span className="thcp-dialog-confirmation-instructions">
              Please arrive at the dispensary between:
            </span>
            <br />
            <span className="thcp-selected-time-frame">
              {selectedDispensary.reservations
                .find((r) => r.id === selectedTime)
                ?.description.split("-")[0]
                .trim()}
            </span>

            <div style={{ width: "100%", marginTop: "1rem" }}>
              <Button
                label="Precheck"
                iconPos="right"
                className="thcp-dialog-button"
                onClick={handlePrecheck}
                loading={loadingPrecheck}
              >
                <img alt="thcp-icon" src="icons/cannabis-outline.png" />
              </Button>
            </div>
          </div>
        )}
        {shared === 2 && (
          <div className="thcp-dialog-confirmation success">
            <div className="thcp-dialog-logo-content">
              <div className="thcp-dialog-verified-img">
                <img
                  alt="thcp_img"
                  src="imgs/thcp-verified-md.png"
                  style={{ width: "5rem" }}
                ></img>
              </div>
            </div>
            <p>
              You are set for your visit <br />
              Please arrive at the dispensary between:
            </p>
            <br />
            <span className="thcp-selected-time-frame">
              {
                selectedDispensary.reservations.find(
                  (r) => r.id === selectedTime
                )?.description
              }
            </span>
            <span className="thcp-selected-time-frame">
              @{selectedDispensary.name}
            </span>
          </div>
        )}
        {shared === 3 && (
          <div className="thcp-dialog-verification">
            <div className="thcp-dialog-logo-content">
              <div className="thcp-dialog-verified-img">
                <img
                  alt="thcp_img"
                  src="icons/thcp-verified.png"
                  style={{ width: "5rem" }}
                ></img>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <Button
                label="Get Verified Now"
                className="thcp-dialog-button-verify"
                onClick={handleForceVerification}
              ></Button>
            </div>
            <ul className="list-items-verify">
              <li>Take a picture of your ID & selfie</li>
              <li>Automatically filled profile info</li>
              <li>Access to all benefits & features</li>
            </ul>
          </div>
        )}
        {shared === 4 && (
          <div className="thcp-dialog-verification">
            <div className="thcp-qr-verification">
              <img
                alt="thcp_img"
                src={imgQr}
                style={{ width: "100%" }}
                // onLoad={() => setImageLoaded(true)}
              ></img>
            </div>

            <div className="thcp-qr-msg">
              <div className="thcp-qr-img-msg">
                <i className="pi pi-mobile icon-movil" />
              </div>
              <p>SCAN ME</p>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default PrecheckDialog;
