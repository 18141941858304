import { Card } from "primereact/card";
import { passport } from "src/app/constants/passport.constants";

export const MethodTemplate = ({ listMethod }) => {
  const style = {
    passportCard: {
      borderRadius: "12px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      fontFamily: '"Poppins", sans-serif',
      textAlign: "center",
      height: "100%",
      justifyContent: "center",
      padding: 0,
      margin: 0,
    },
    titleCard: {
      color: "#36b37e",
      fontSize: "1.1rem",
      fontWeight: 700,
    },
    listMethod: {
      display: "flex",
      padding: "0",
      gap: "5px",
    },
    passportItems: {
      background: "#f1f2ba",
      borderRadius: "12px",
      width: "max-content",
      padding: " 0 1rem",
      height: "22px",
      fontSize: "14px",
      fontWeight: "600",
      color: "#696c72",
    },
  };
  return (
    <td style={{ width: "50%", height: "1rem" }}>
      <Card
        style={style.passportCard}
        title={<span style={style.titleCard}>{passport.TITLE4}</span>}
      >
        <table>
          {listMethod?.map((item, i) =>
            i % 2 === 0 ? (
              <tr key={i / 2}>
                <td>
                  <div style={style.passportItems}>{item}</div>
                </td>
                {listMethod[i + 1] && (
                  <td>
                    <div style={style.passportItems}>{listMethod[i + 1]}</div>
                  </td>
                )}
              </tr>
            ) : null
          )}
        </table>
      </Card>
    </td>
  );
};
