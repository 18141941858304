import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import "./emailAddresses.css";
import { CannabisDispensariesService } from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";
import { Toast } from "primereact/toast";
import { encryptedData } from "src/app/shared/helpers/encrypt";
import { createEventGA } from "src/app/shared/helpers/analytics";
import { Divider } from "src/app/shared/components/common/divider/Divider";

export const EmailAddresses = ({ data, setIsChange }) => {
  const appType = AppType();
  const dispensaryService = new CannabisDispensariesService();
  const toast = useRef(null);

  const [mainEmail, setMainEmail] = useState(data?.email);
  const [additionalEmails, setAdditionalEmails] = useState(
    data?.emailsAddeds || []
  );

  const onChangeAdditionalEmail = (value, index) => {
    setAdditionalEmails((prev) => {
      return [...prev.slice(0, index), value, ...prev.slice(index + 1)];
    });
  };

  const onChangeEmail = () => (e) => {
    const value = e.target.value;
    setMainEmail(value);
  };

  const handleAddEmail = (e) => {
    setAdditionalEmails((prev) => [...prev, ""]);
  };

  const handleRemoveEmail = (index) => {
    setAdditionalEmails((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  };
  const saveEmail = async (e) => {
    e.preventDefault();
    const payload = {
      emails: {
        main: mainEmail,
        add: additionalEmails,
      },
    };
    const dataEncrypt = encryptedData(payload);
    const [disErr] = await dispensaryService.update(
      { data: dataEncrypt },
      appType
    );
    if (disErr) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating information",
      });
      return;
    }

    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Email successfully added",
    });
    createEventGA(
      "Dispensary",
      "update_settings_email_dispensary",
      "Update Settings in tab email dispensary"
    );
    setIsChange(true);
  };

  return (
    <div>
      <Toast ref={toast}></Toast>

      <form className="thcp-dispensary-signup-form" onSubmit={saveEmail}>
        <span className="p-input-icon-left">
          <InputText value={mainEmail} onChange={onChangeEmail()} />
          <i className="pi pi-envelope" />
          <span className="thcp-float-label">Preferred Email</span>
        </span>

        {additionalEmails.map((email, index) => (
          <div className="additional-email-input-container">
            <span
              className="p-input-icon-left additional-email-input"
              key={index}
            >
              <InputText
                value={email}
                onChange={(e) => onChangeAdditionalEmail(e.target.value, index)}
              />
              <i className="pi pi-envelope" />
              <span className="thcp-float-label">Email</span>
            </span>
            <Button
              onClick={() => handleRemoveEmail(index)}
              type="button"
              className="p-button-danger"
              label="Remove"
            />
          </div>
        ))}

        <Button
          onClick={handleAddEmail}
          type="button"
          icon="pi pi-plus"
          label="Add"
          className={`thcp-form-button-submit`}
        />
        <Divider />
        <div className="save-button-container">
          <Button
            type="submit"
            label="Save"
            icon="pi pi-save"
            className={`thcp-form-button-submit`}
          />
        </div>
      </form>
    </div>
  );
};
