import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { AuthService } from "src/app/shared/services";

export const ResetPasswordForm = () => {
  const toast = useRef(null);
  const [isViewPass, setIsViewPass] = useState(false);
  const [submittedOne, setSubmittedOne] = useState(false);

  const [dataForm, setDataForm] = useState({
    email: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onChangeFields = (field) => (e) => {
    const { value } = e.target;
    setDataForm({ ...dataForm, [field]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmittedOne(true);

    if (Object.values(dataForm).some((value) => value === "")) {
      toast.current.show({
        severity: "warn",
        summary: "Error",
        detail: "All fields are required",
        life: 1500,
      });
      return;
    }

    if (dataForm.newPassword !== dataForm.confirmPassword) {
      toast.current.show({
        severity: "warn",
        summary: "Error",
        detail: "Passwords do not match",
        life: 1500,
      });
      return;
    }

    const authService = new AuthService();
    const [error, resp] = await authService.resetPasswordUser({
      ...dataForm,
    });

    if (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 1500,
      });
      return;
    } else {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.message,
        life: 2000,
      });
      window.location.href = "https://www.thcprecheck.com/log-in";
    }
  };

  return (
    <div className="thcp-dispensary-signin-container">
      <Toast ref={toast} />
      <h2 className="thcp-form-title">Reset Password</h2>
      <form className="thcp-dispensary-signin-form">
        <span className="p-input-icon-left">
          <i className="pi pi-user" />
          <InputText
            placeholder=""
            value={dataForm.email}
            onChange={onChangeFields("email")}
            className={submittedOne && !dataForm.email && "p-invalid"}
          />
          <span className="thcp-float-label">E-mail</span>
        </span>
        <span className="p-input-icon-left">
          <i
            className={`pi
                ${isViewPass ? "pi-eye-slash" : "pi-eye"}
                icons-view-pass`}
            onClick={() => setIsViewPass(!isViewPass)}
          />
          <InputText
            placeholder=""
            type={!isViewPass ? "password" : null}
            value={dataForm.newPassword}
            onChange={onChangeFields("newPassword")}
            feedback={false}
            className={
              submittedOne && !dataForm.newPassword
                ? "thcp-input-password p-invalid"
                : "thcp-input-password"
            }
          />
          <span className="thcp-float-label">New password</span>
        </span>

        <span className="p-input-icon-left">
          <i
            className={`pi
                ${isViewPass ? "pi-eye-slash" : "pi-eye"}
                icons-view-pass`}
            onClick={() => setIsViewPass(!isViewPass)}
          />
          <InputText
            placeholder=""
            type={!isViewPass ? "password" : null}
            value={dataForm.confirmPassword}
            onChange={onChangeFields("confirmPassword")}
            feedback={false}
            className={
              submittedOne && !dataForm.confirmPassword
                ? "thcp-input-password p-invalid"
                : "thcp-input-password"
            }
          />
          <span className="thcp-float-label">Confirm new password</span>
        </span>
        <div>
          <Button
            onClick={handleSubmit}
            label="Change my password"
            className="thcp-form-button btn-reset"
          />
        </div>
      </form>
    </div>
  );
};
