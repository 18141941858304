import "./dispensary-info.css";

export const DispensaryName = ({ name, isPartner }) => {
  return (
    <div className="info-label container-name">
      {isPartner && <img src={"/imgs/thc-precheck-logo.png"} alt="logo" />}
      <p className="name-dispensary">{name || "No name"}</p>
    </div>
  );
};

export const DispensaryRating = ({ rating }) => {
  return (
    <div className="info-label">
      <p className="rating-dispensary">
        {rating ? `${rating.toFixed(1)} Google rating` : "No rating"}
      </p>
    </div>
  );
};

export const DispensaryAddress = ({ address, isoCode, city }) => {
  return (
    <div className="info-label">
      <img src={"/icons/v2/icon-location-black.png"} alt="address-icon" />
      <p className="address-dispensary">
        {address || "No address"}, {city}, {isoCode}
      </p>
    </div>
  );
};

export const DispensaryState = ({ isoCode, city }) => {
  return (
    <div className="info-label">
      <img src={"/icons/v2/icon-location-black.png"} alt="address-icon" />
      <p className="address-dispensary">
        {city || "No city"}
        {", "}
        {isoCode || "No state"}
      </p>
    </div>
  );
};

export const DispensaryVisitStore = ({ storeUrl }) => {
  return (
    <div className="info-label">
      <img src={"/icons/v2/store.png"} alt="address-icon" />
      <a href={storeUrl} target="_blank" rel="noopener noreferrer">
        <p className="address-dispensary">Visit Store</p>
      </a>
    </div>
  );
};
