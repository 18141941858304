import React from "react";
import "./open-preferences.css";

export const OpenPreferences = ({ open, setOpen }) => {
  return (
    <>
      <div className="open-preferences-container">
        <div
          className="open-preferences-container-div"
          onClick={() => setOpen(!open)}
        >
          OPEN PREFERENCES
          <img
            src={`/icons/v2/${open ? "close-pref.png" : "open-pref.png"}`}
            alt="logo"
          />
        </div>
      </div>
      <div className="div-border-radius"></div>
    </>
  );
};
