import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

import { ReportService } from "src/app/shared/services";
import { getFormattedDate, parseToReportCSV } from "./compliance-report";
import useAuth from "src/app/shared/hooks/useAuth";

import "./compliance-report.css";
import { AppType } from "src/app/shared/hooks/AppType";
import { createEventGA } from "src/app/shared/helpers/analytics";

const ComplianceReport = () => {
  const { user, setUser } = useAuth();
  const appType = AppType();
  const toast = useRef(null);
  const dt = useRef(null);
  
  const [reports, setReports] = useState([]);
  const [reload, setReload] = useState(0);
  const [rangeDate, setRangeDate] = useState(null);
  const [reportCSV, setReportCSV] = useState([]);
  const todayFomatted = getFormattedDate();

  const rangeDates = [
    { name: "Past month", value: 1 },
    { name: "Past 3 months", value: 3 },
    { name: "Past 6 months", value: 6 },
    { name: "Past year", value: 12 },
  ];

  const generateReportByDoc = (rowData) => () => {
    const csvData = parseToReportCSV(rowData);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Report generated successfully",
      life: 1500,
    });
    createEventGA(
      "Dispensary",
      "download_report_dispensary",
      "Download Report dispensary"
    );
    setReportCSV(csvData);

    setTimeout(() => {
      const csvBtn = document.getElementById("thcp-csv-btn");
      csvBtn.click();
    }, 1000);
  };

  const generateReport = async () => {
    const reportService = new ReportService();
    if (!rangeDate) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Select a time frame",
        life: 1500,
      });
      return;
    }

    const [err, saveRes] = await reportService.register(
      { rangeDate: rangeDate },
      appType
    );
    if (err || !saveRes) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error generating report",
        life: 1500,
      });
      return;
    }
    const csvData = parseToReportCSV(saveRes);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Report generated successfully",
      life: 1500,
    });
    createEventGA(
      "Dispensary",
      "generate_report_dispensary",
      "Generate Report dispensary"
    );
    setReportCSV(csvData);
    setTimeout(() => {
      const csvBtn = document.getElementById("thcp-csv-btn");
      csvBtn.click();
    }, 1000);
    setRangeDate(null);
    setReload(reload + 1);
  };

  const handleRefreshList = () => {
    setReload(reload + 1);
  };

  const header = (
    <div className="thcp-compliance-table-header">
      <h4>Compliance Report</h4>
      <i
        className="pi pi-refresh"
        style={{ fontSize: "1.5rem" }}
        onClick={handleRefreshList}
      ></i>
      <div className="thcp-compliance-table-content-right">
        <label>Range Time</label>
        <Dropdown
          value={rangeDate}
          options={rangeDates}
          optionLabel="name"
          style={{ width: "200px" }}
          placeholder="Select a time range"
          onChange={(e) => setRangeDate(e.value)}
        />
        <Button
          label="Generate"
          type="button"
          icon="pi pi-file"
          rounded
          onClick={generateReport}
          data-pr-tooltip="CSV"
          iconPos="right"
          className="bg-green"
        />
        <CSVLink
          data={reportCSV}
          filename={`${todayFomatted}.csv`}
          style={{ display: "none" }}
          id="thcp-csv-btn"
        >
          Generate
        </CSVLink>
      </div>
    </div>
  );

  useEffect(() => {
    const reportService = new ReportService();
    reportService
      .getByDispensary(user.userId, appType)
      .then(([_, data]) => setReports(data))
      .catch(([err]) => console.error(err));

    const intervalId = setInterval(() => {
      reportService
        .getByDispensary(user.userId, appType)
        .then(([_, data]) => setReports(data))
        .catch(([err]) => console.error(err));
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [reload, setUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const rangeBody = (rowData) => <>{`${rowData.rangeDate} months`}</>;
  const requestDate = (rowData) => <>{rowData.requestDate.split("T")[0]}</>;

  const statusBodyTemplate = (rowData) => {
    return (
      <div className="thcp-button-container">
        <Button
          label="Download File"
          onClick={generateReportByDoc(rowData)}
          text
        />
      </div>
    );
  };

  return (
    <div className="thcp-precheck-container">
      <Toast ref={toast} />
      <div className="thcp-precheck-table-container thcp-table-header-white">
        <div className="card-content">
          <DataTable
            ref={dt}
            header={header}
            value={reports}
            tableStyle={{ minWidth: "50rem" }}
            className="thcp-precheck-table"
            paginator
            rows={12}
            emptyMessage="No reports found."
          >
            <Column
              className="thcp-first"
              field="_id"
              header="Report ID"
              style={{ width: "220px" }}
            />
            <Column
              field="timeFrame"
              header="Request Date"
              body={requestDate}
            />
            <Column field="rangeDate" header="Range Date" body={rangeBody} />
            <Column header="Actions" body={statusBodyTemplate} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default ComplianceReport;
