import authClient from "../utils/auth-cookie-client.utils";

export class SupportService {
  async contactUs(payload, appType) {
    try {
      const response = await authClient(appType).post(
        "/support/contact-us",
        payload
      );
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
}
