import { useState } from "react";
import { LoaderContext } from "./loaderContext";
import { ClipLoader, SyncLoader } from "react-spinners";

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading && (
        <div className="loader-container">
          {" "}
          <SyncLoader
            color={"#36b37e"}
            loading={isLoading}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {children}
    </LoaderContext.Provider>
  );
};
