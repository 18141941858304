import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { AppType } from "src/app/shared/hooks/AppType";
import { UserService } from "src/app/shared/services";
import "./dialogVerify.css";
import { createEventGA } from "src/app/shared/helpers/analytics";

export const DialogVerify = ({ dialogVisible, setDialogVisible, path }) => {
  const appType = AppType();
  const toast = useRef(null);

  const [shared, setShared] = useState(0);
  const [isMovil, setIsMovil] = useState(false);
  const [imgQr, setImgQr] = useState(null);

  const handleOnHide = () => {
    setDialogVisible(false);
    setShared(0);
  };

  const handleForceVerification = async (e) => {
    const userService = new UserService();
    const payload = { path };
    const [err, data] = await userService.forceVerification(appType, payload);
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Internal error",
        life: 1500,
      });
      return;
    }
    createEventGA("User", "verify_id_user", "Verify id user");
    if (!isMovil && data?.qr) {
      setShared(2);
      setImgQr(data?.qr);
    } else {
      setDialogVisible(false);
      //   setShared(0);
      window.location.replace(data?.url);
    }
  };

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobile = /iphone|ipod|android|ie|blackberry|fennec/.test(userAgent);
    setIsMovil(mobile);
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header={""}
        visible={dialogVisible}
        style={{ width: "90vw", maxWidth: "350px", minHeight: "150px" }}
        onHide={handleOnHide}
        className="thcp-precheck-dialog-content"
      >
        {shared === 0 && (
          <div className="thcp-dialog-verification">
            <div className="thcp-dialog-logo-content">
              <div className="thcp-dialog-verified-img">
                <img
                  alt="thcp_img"
                  src="/icons/thcp-verified.png"
                  style={{ width: "6rem" }}
                ></img>
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <Button
                label="Get Verified Now"
                className="thcp-dialog-button-verify"
                onClick={handleForceVerification}
              ></Button>
            </div>
            <ul className="list-items-verify">
              <li>Take a picture of your ID & selfie</li>
              <li>Automatically filled profile info</li>
              <li>Access to all benefits & features</li>
            </ul>
            <div className="thcp-dialog-skip">
              <p onClick={handleOnHide}>Skip for now</p>
            </div>
          </div>
        )}
        {shared === 2 && (
          <div className="thcp-dialog-verification">
            <div className="thcp-qr-verification">
              <img
                alt="thcp_img"
                src={imgQr}
                style={{ width: "100%" }}
                // onLoad={() => setImageLoaded(true)}
              ></img>
            </div>

            <div className="thcp-qr-msg">
              <div className="thcp-qr-img-msg">
                <i className="pi pi-mobile icon-movil" />
              </div>
              <p>SCAN ME</p>
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};
