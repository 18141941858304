import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";

import useAuth from "src/app/shared/hooks/useAuth";
import { PreferenceService, UserService } from "src/app/shared/services";
// import NavBarComponent from "src/app/shared/components/navBar/navBar.component";
import { Experience } from "./steps/experience/Experience";

import "./preferences.css";
import { mapPreferences, mapUpdatePreferences } from "./preferences";
import { AppType } from "src/app/shared/hooks/AppType";
import { EnjoyingCannabis } from "./steps/enjoyingCannabis/EnjoyingCannabis";
import { ConsumingCannabis } from "./steps/consumingCannabis/ConsumingCannabis";
import { AvoidedCannabis } from "./steps/avoidedCannabis/AvoidedCannabis";
import { encryptedData } from "src/app/shared/helpers/encrypt";
import { createEventGA } from "src/app/shared/helpers/analytics";
// import { HeaderHome } from "src/app/shared/components/header-home";
// import { GoToProfile } from "src/app/shared/components/go-to-profile";

const CannabisPreferencesPage = () => {
  const appType = AppType();

  const { user } = useAuth();
  const [cannabisPreferences, setCannabisPreferences] = useState([]);
  const [optionStep, setOptionStep] = useState(1);
  const [selectOptionCard, setSelectOptionCard] = useState(false);
  useEffect(() => {
    async function getUserPreferences() {
      const preferenceService = new PreferenceService();
      const userService = new UserService();
      const [_, userRes] = await userService.getCurrentUser(appType);
      const [__, prefeRes] = await preferenceService.getPreferences(appType);
      if (_ || __) {
        setCannabisPreferences([]);
      }
      const preferencesMapped = mapPreferences(prefeRes, userRes);
      setCannabisPreferences(preferencesMapped);
    }
    getUserPreferences();

    createEventGA("User", "open_preferences_user", "Open Preferences");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectPreference = async (
    preferenceId,
    optionId,
    check,
    step
  ) => {
    const userService = new UserService();

    const selectedPreference = cannabisPreferences.find(
      (cps) => cps._id === preferenceId
    );

    const selectItemWithOpt = !Array.isArray(optionId)
      ? selectedPreference.options
          .find((cpo) => cpo.items.find((item) => item.id === optionId))
          ?.items.find((item) => item.id === optionId)
      : selectedPreference.options
          .map((cpo) => cpo.items.filter((item) => optionId.includes(item.id)))
          .filter((items) => items.length > 0)
          .flatMap((items) => items);

    const selectedOption =
      step !== 2 && step !== 3
        ? selectedPreference.options.find((cpo) => cpo.id === optionId)
        : selectItemWithOpt;

    setCannabisPreferences(
      mapUpdatePreferences(
        cannabisPreferences,
        {
          preferenceId,
          selectedOption,
        },
        step
      )
    );
    const payload = {
      preferenceId: optionId,
      checked: check,
    };

    const [err] = await userService.updateUserPreference(
      user?.username,
      { data: encryptedData(payload) },
      appType
    );
    if (err) {
      console.error(err);
    }
  };

  const getStep = () => {
    return optionStep === 1 ? (
      <Experience
        data={cannabisPreferences[0]}
        select={setSelectOptionCard}
        handleSelect={handleSelectPreference}
      />
    ) : optionStep === 2 ? (
      <EnjoyingCannabis
        data={cannabisPreferences[1]}
        select={setSelectOptionCard}
        handleSelect={handleSelectPreference}
      />
    ) : optionStep === 3 ? (
      <ConsumingCannabis
        data={cannabisPreferences[2]}
        select={setSelectOptionCard}
        handleSelect={handleSelectPreference}
      />
    ) : (
      <AvoidedCannabis
        data={cannabisPreferences[3]}
        select={setSelectOptionCard}
        handleSelect={handleSelectPreference}
      />
    );
  };

  return (
    <>
      <div className="container-preferencess">
        <Link
          to={optionStep === 1 && "/user/profile"}
          onClick={() => optionStep !== 1 && setOptionStep(optionStep - 1)}
          className="back-pref"
        >
          <i className="pi pi-angle-left" />
          Back
        </Link>
        <div className="container-data-opt">
          {getStep()}
          <div className="container-steps-btn">
            <div>
              <p className="info-step">
                <strong>{optionStep}</strong>/4
              </p>
              <div className="container-img-steps">
                {Array.from({ length: optionStep }).map((_, imgIndex) => (
                  <img
                    key={imgIndex}
                    src="/icons/v2/steps/step-green.png"
                    alt={`logo ${imgIndex}`}
                  />
                ))}
                {Array.from({ length: 4 - optionStep }).map((_, imgIndex) => (
                  <img
                    key={imgIndex}
                    src="/icons/v2/steps/step-gray.png"
                    alt={`logo ${imgIndex}`}
                  />
                ))}
              </div>
            </div>
            <Link
              className="btn-next"
              to={optionStep === 4 && "/user/profile"}
              onClick={() => optionStep !== 4 && setOptionStep(optionStep + 1)}
            >
              NEXT
              <img src="/icons/v2/icon-rigth-next.png" alt="" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CannabisPreferencesPage;
