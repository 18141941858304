import { format, parse } from "date-fns";

export const filteredTimes = (data) =>
  data.filter((option) => {
    const now = new Date();
    const currentTime = format(now, "hh:mm a");
    const startTime = option.description.split(" - ")[0];
    return (
      parse(startTime, "hh:mm a", new Date()) >
      parse(currentTime, "hh:mm a", new Date())
    );
  });

export const createNumberConfirmation = () =>
  Math.floor(Math.random() * 90000) + 10000;
