import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { AuthService } from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";

import "./change_password.css";

const ChangePasswordPage = () => {
  const toast = useRef(null);
  const appType = AppType();

  const [currentUser, setCurrentUser] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const onInputChange = (field) => (e) => {
    const value = e.target.value;
    setCurrentUser({ ...currentUser, [field]: value });
  };

  const handleUpdateUser = async () => {
    const authService = new AuthService();
    if (currentUser.newPassword !== currentUser.confirmPassword) {
      toast.current.show({
        severity: "warn",
        summary: "Alert",
        detail: "Passwords missmatch",
        life: 1500,
      });
      return;
    }
    const [err] = await authService.changePassword(currentUser, appType);
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error updating user information",
        life: 1500,
      });
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "User info updated successfully",
      life: 1500,
    });
    setCurrentUser({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  return (
    <>
      <Toast ref={toast} />
      {/* <NavBarComponent /> */}
      <div className="container-settings-change-password">
        <div className="settings-header change-header">
          <Link to={"/user/profile/settings"} className="back-home">
            <i className="pi pi-angle-left" />
            Back
          </Link>
          <p>Change Password</p>
        </div>
        <form className="personal-info-form">
          <span className="p-input-icon-left">
            <Password
              className="thcp-input-password"
              value={currentUser.currentPassword}
              onChange={onInputChange("currentPassword")}
              // feedback={false}
            />
            <span className="thcp-float-label">Current password</span>
          </span>
          <span className="p-input-icon-left">
            <Password
              className="thcp-input-password"
              value={currentUser.newPassword}
              onChange={onInputChange("newPassword")}
            />
            <span className="thcp-float-label">New password</span>
          </span>
          <span className="p-input-icon-left">
            <Password
              className="thcp-input-password"
              value={currentUser.confirmPassword}
              onChange={onInputChange("confirmPassword")}
            />
            <span className="thcp-float-label">Confirm password</span>
          </span>
        </form>
        <div className="thcp-profile-nav-buttons personal-info-buttons">
          <Link
            className="personal-info-buttons_save"
            onClick={handleUpdateUser}
          >
            Save
          </Link>
        </div>
      </div>
    </>
  );
};

export default ChangePasswordPage;
