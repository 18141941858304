import React from 'react';
import { Link } from 'react-router-dom';

import './sidebar.css';
import useAuth from 'src/app/shared/hooks/useAuth';

const DashboardSidebar = ({ setActive }) => {
  const { user } = useAuth();

  const handleOnClick = (e) => {
    e.preventDefault();
    const prevEl = document.querySelectorAll('.active');
    prevEl?.forEach(el => el.classList.remove('active'));
    e.target.classList.add('active');
    setActive(+e.target.dataset.id);
  }

  const isAdmin = () => user.rol === 'thcp_dispensary' && user.dispensaryType === 'thcp_administrator';

  return (
    <aside className='thcp-sidebar-container'>
      <Link className='thcp-sidebar-link' onClick={handleOnClick} data-id='1'>
        <i className='pi pi-users' style={{ fontSize: '2rem' }}></i>
        <span>Appointment Manager</span>
      </Link>
      <Link className='thcp-sidebar-link' onClick={handleOnClick} data-id='2'>
        <i className='pi pi-shopping-cart' style={{ fontSize: '2rem' }}></i>
        <span>Budtender Queue</span>
      </Link>
      <Link className='thcp-sidebar-link' onClick={handleOnClick} data-id='3'>
        <i className='bi bi-journal-text' style={{ fontSize: '1.8rem', height: '26px' }}></i>
        <span>History</span>
      </Link>
      {isAdmin() && <Link className='thcp-sidebar-link' onClick={handleOnClick} data-id='4'>
        <i className='pi pi-file-export' style={{ fontSize: '2rem' }}></i>
        <span>Compliance Report</span>
      </Link>}
      {isAdmin() && <Link className='thcp-sidebar-link' onClick={handleOnClick} data-id='5'>
        <i className='pi pi-cog' style={{ fontSize: '2rem' }}></i>
        <span>Settings</span>
      </Link>}
    </aside>
  )
}

export default DashboardSidebar;
