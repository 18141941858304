import { addHours } from "date-fns";

export const parseToReportCSV = ({ data, ...report }) => {
  // Encabezado del reporte
  const reportHeader = [
    ["Report ID", report._id || "-"],
    ["Request Date", report.requestDate || "-"],
    ["Dispensary Name", data[0]?.dispensary?.name || "-"],
    [], // Línea vacía como separador
  ];

  // Encabezado de los datos detallados
  const dataHeader = [
    [
      "Confirmation Number",
      // "Range Date",
      "User Name",
      "User Lastname",
      "User Birthdate",
      "User Email",
      "User Phone",
      "User Verification Type",
      "Service Type",
      "Precheck time",
      "Date of visit",
    ],
  ];

  // Datos detallados
  const detailedData = data.map((p) => [
    p.confirmation || "-",
    p.user.name || "-",
    p.user.lastName || "-",
    new Date(p.user.birthDate).toISOString().split("T")[0] || "-",
    p.user.email || "-",
    p.user.phoneNumber || "-",
    `${p.user.verifiedType} - Passed` || "-",
    p.serviceType || "-",
    p.timeFrame || "-",
    addHours(new Date(p.createdAt), -5).toLocaleString() || "-",
  ]);

  return [...reportHeader, ...dataHeader, ...detailedData];
};

export const getFormattedDate = () =>
  new Date().toLocaleDateString("en").replaceAll("/", "-");
