import { useRef } from "react";
import { Link } from "react-router-dom";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { UserService } from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";
import { createEventGA } from "src/app/shared/helpers/analytics";
import { cleanCookie } from "src/app/shared/utils/cookies.utils";
import { COOKIES } from "src/app/constants/cookies.constants";
import useAuth from "src/app/shared/hooks/useAuth";
import "./settings.css";

export const Settings = () => {
  const appType = AppType();
  const { user, setUser } = useAuth();
  const toast = useRef(null);

  const handleLogOut = () => {
    setUser(null);
    createEventGA("User", "logout_user", "Logout app user");
    cleanCookie(COOKIES.ACCESS_TOKEN);
  };

  const handleUploadImage = async (event) => {
    const formData = new FormData();
    const userService = new UserService();
    formData.append("profilePic", event.files[0]);

    const [err, uploadRes] = await userService.uploadProfileImage(
      formData,
      appType
    );
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error uploading image",
        life: 1500,
      });
      event.options.clear();
      return;
    }
    createEventGA("User", "change_img_profile_user", "Change img profile");
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Precheck set to No Show",
      life: 1500,
    });
    event.options.clear();
    setUser({ ...user, profileImg: uploadRes.userImage });
  };

  const handleFindDispensary = () => {
    createEventGA("User", "find_dispensarys_user", "Finder Dispensarys");
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="container-settings">
        <div className="settings-header">
          <Link to={"/user/profile"} className="back-home">
            <i className="pi pi-angle-left" />
            Back
          </Link>
          <img
            alt="thcp_img_logo"
            src="/imgs/logo.png"
            className="logo-header-settings"
          />
        </div>
        <div className="settings-info">
          <div className="thcp-profile-card-container">
            <div className="thcp-container-top">
              <h2>{`${user?.firstName === "Jhon" ? "" : user?.firstName} ${
                user?.lastName === "Doe" ? "" : user?.lastName
              }`}</h2>
              <div className="thcp-profile-img-settings">
                <img
                  className="thcp-profile-image-settings"
                  src={user.profileImg?.url || "/imgs/default-user-pic.png"}
                  alt="user_img"
                />
                <FileUpload
                  className="thcp-upload-img-btn"
                  mode="basic"
                  name="profilePic"
                  accept="image/*"
                  customUpload
                  auto
                  uploadHandler={handleUploadImage}
                />
                <i
                  className="thcp-upload-icon pi pi-camera"
                  style={{ fontSize: "2rem" }}
                ></i>
              </div>
              <div className="thcp-profile-status">
                <p
                  className={`${
                    user?.verifiedStatus !== "accept" ? "verify-action" : ""
                  }`}
                  // onClick={() => {
                  //   user?.verifiedStatus !== "accept" && setDialogVisible(true);
                  // }}
                >
                  {user?.verifiedStatus === "accept"
                    ? "Verified"
                    : "Not verified"}
                </p>
                <img src="/icons/thcp-verified.png" alt="logo" />
              </div>
            </div>
            <div className="thcp-container-bottom">
              <Link
                className="thcp-profile-option link-pi"
                to="/user/profile/settings/personal-info"
              >
                <i
                  className="pi pi-lock"
                  style={{ fontSize: "2rem", marginRight: "1rem" }}
                />
                Personal Info
                <i
                  className="pi pi-angle-right"
                  style={{ fontSize: "2rem" }}
                ></i>
              </Link>
              <Link
                className="thcp-profile-option"
                to="/user/profile/settings/precheck-history"
              >
                <i
                  className="pi pi-history"
                  style={{ fontSize: "2rem", marginRight: "1rem" }}
                ></i>
                THC Precheck History
                <i
                  className="pi pi-angle-right"
                  style={{ fontSize: "2rem" }}
                ></i>
              </Link>
              <Link
                className="thcp-profile-option"
                to="/user/profile/settings/change-password"
              >
                <i
                  className="pi pi-key"
                  style={{ fontSize: "2rem", marginRight: "1rem" }}
                ></i>
                Change Password
                <i
                  className="pi pi-angle-right"
                  style={{ fontSize: "2rem" }}
                ></i>
              </Link>
              <Link
                className="thcp-profile-option"
                to="/user/profile/settings/contact-us"
              >
                <i
                  className="pi pi-user"
                  style={{ fontSize: "2rem", marginRight: "1rem" }}
                ></i>
                <i
                  className="pi pi-angle-right"
                  style={{ fontSize: "2rem" }}
                ></i>
                Contact Us
              </Link>
              <Link
                className="thcp-profile-option no-border"
                onClick={handleLogOut}
              >
                <i
                  className="pi pi-angle-left"
                  style={{ fontSize: "2rem", marginRight: "1rem" }}
                ></i>
                Logout
              </Link>
            </div>
          </div>
          <div className="container-btn-finder">
            <Link
              className="container-btn-find-dispensary settings-dispensary"
              to="/search-dispensaries"
              onClick={handleFindDispensary}
            >
              <img src="/icons/v2/icon-left-finder.png" alt="thcp_logo" />
              <p>DISPENSARY FINDER</p>
              <img src="/icons/v2/icon-rigth-finder.png" alt="thcp_logo" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
