import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProgressBar } from "primereact/progressbar";
import { PrecheckService } from "src/app/shared/services";
import useAuth from "src/app/shared/hooks/useAuth";
import { AppType } from "src/app/shared/hooks/AppType";
import { createEventGA } from "src/app/shared/helpers/analytics";
import "./precheck-history.css";

const PrecheckHistoryPage = () => {
  const appType = AppType();
  const [precheckList, setPrecheckList] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const precheckService = new PrecheckService();
    precheckService
      .getUserPrechecks(user?.username, appType)
      .then(([err, data]) =>
        !err ? setPrecheckList(data) : setPrecheckList([])
      )
      .catch((err) => console.log(err));

    createEventGA(
      "User",
      "view_history_precheck_user",
      "View history precheck"
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="container-settings-precheck-history">
        <div className="settings-header precheck-history-header">
          <Link to={"/user/profile/settings"} className="back-home">
            <i className="pi pi-angle-left" />
            Back
          </Link>
          <p>Precheck History</p>
        </div>
        {/* <div className="thcp-profile-preferences-container"> */}
        {/* <h2>THC Precheck History</h2> */}
        <div className="thcp-precheck-progress">
          <ProgressBar
            value={(precheckList.length || 1) * (precheckList.length ? 5 : 1)}
            className="thcp-progress-bar orange"
          ></ProgressBar>
          <div className="thcp-progress-details">
            <div className="thcp-progress-details-content">
              <span>Precheck to date</span>
              <span className="thcp-progress-count">
                {precheckList.length || 0}
              </span>
            </div>
            <div className="thcp-progress-details-content right">
              <span>Next reward</span>
              <span className="thcp-progress-count">20</span>
            </div>
          </div>
        </div>
        <div className="thcp-precheck-history">
          {precheckList.map((p, i) => (
            <div
              className={`thcp-precheck-history-row ${
                i === precheckList.length - 1 ? "no-border-history" : ""
              }`}
              key={i}
            >
              <h4>
                {p.dispensary.name} <br /> Dispensary
              </h4>
              <h4>
                {p.createdAt && new Date(p.createdAt).toLocaleDateString("en")}
              </h4>
              <span style={{ color: `${p.isActive ? "green" : "red"}` }}>
                {p.isActive ? "Active" : "Inactive"}
              </span>
            </div>
          ))}
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default PrecheckHistoryPage;
