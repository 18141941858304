import { useEffect, useState } from "react";
import { titlesHome } from "src/app/constants/passport.constants";
import { Link } from "react-router-dom";
import { CannabisDispensariesService } from "src/app/shared/services";
import useAuth from "src/app/shared/hooks/useAuth";
import "./preferred-dispensary.css";

export const PreferredDispensary = () => {
  const { user } = useAuth();
  const [dispensaries, setDispensaries] = useState([]);

  useEffect(() => {
    const cannabisDispensariesService = new CannabisDispensariesService();
    cannabisDispensariesService
      .search()
      .then(([err, res]) => {
        if (err) {
          setDispensaries([]);
        } else {
          const processedRes =
            process.env.REACT_APP_ENV === "production"
              ? res.filter((item) => !item.isTesting)
              : res;
          const dispensary = processedRes.filter(
            (dis) => dis.name === user.prodReccDisp
          );
          setDispensaries(dispensary);
        }
      })
      .catch((_) => setDispensaries([]));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="preferred-container">
      <div className="preferred-container-header">
        <img src={"/icons/v2/icon-activity.png"} alt="logo" />
        <p>{titlesHome.TITLE1}</p>
      </div>
      <div className="preferred-container-dispensary">
        <div className="preferred-container-dispensary-info">
          <img
            className="preferred-container-dispensary-info-image"
            src={"/icons/thcp-verified.png"}
            alt="icon_dispensary"
          />
          <div className="preferred-container-dispensary-info-location">
            <p>{dispensaries[0]?.name || "Smart Catalog"}</p>
            <div className="preferred-container-dispensary-info-city">
              <img src={"/icons/v2/icon-location.png"} alt="logo" />
              <p>{dispensaries[0]?.location || "Thcprecheck"}</p>
            </div>
          </div>
        </div>
        <Link
          to="/user/profile/change-dispensaries"
          className="preferred-container-dispensary-btn"
        >
          <p>CHANGE DISPENSARY</p>
          <img alt="thcp-icon" src="/icons/v2/icon-change-location.png"></img>
        </Link>
      </div>
    </div>
  );
};
