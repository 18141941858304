import React, { useEffect } from "react";
import DispensariesSigninForm from "./components/signin-form";

import "./signin.css";
import { useDispatch } from "react-redux";
import { setAppType } from "src/store/slices/ui/UiSlice";

export const DispensariesSignIn = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAppType("thcp_dispensary"));
  }, []);

  return (
    <div className="thcp-sigin-form-container">
      <div className="thcp-sigin-bg-img">
        <h2>Become part of THC Precheck</h2>
        <img src="/imgs/cannabis-white.png" alt="symbol" />
        <div></div>
        <div className="thcp-overlay"></div>
      </div>
      <div className="container-form">
        <DispensariesSigninForm />
      </div>
    </div>
  );
};
