import { createContext, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  CannabisDispensariesService,
  CannabisDispensariesTestingService,
} from "src/app/shared/services/cannabis-dispensaries.service";
import "./dispensary.css";
import { Button } from "primereact/button";
import {
  DispensaryAddress,
  DispensaryName,
  DispensaryRating,
  DispensaryVisitStore,
} from "../components/dispensary-info/DispensaryInfo";
import { BudtendersList } from "../components/budtender/BudtendersList";
import DatePicker from "react-date-picker";
import { AppointmentDatePicker } from "../components/appointment-date-picker/AppointmentDatePicker";
import { PrecheckService } from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";

export const DispensaryContext = createContext();
const DisepensaryProvider = ({ children }) => {
  const id = useParams().id;
  const [dispensary, setDispensary] = useState(null);
  const [dispensaryPrechecks, setDispensaryPrechecks] = useState([]);
  const cannabisDispensariesService = new CannabisDispensariesService();
  const precheckService = new PrecheckService();
  useEffect(() => {
    cannabisDispensariesService
      .getInfo(id)
      .then((res) => {
        const [error, data] = res;
        if (error) throw error;
        setDispensary(data);
      })
      .catch((_) => setDispensary(null));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const appType = AppType();
  useEffect(() => {
    precheckService
      .getByDispensary({ dispensaryId: id }, appType)
      .then((res) => {
        const [error, data] = res;
        if (error) throw error;
        setDispensaryPrechecks(data);
      });
  }, [appointmentDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DispensaryContext.Provider
      value={{
        dispensary,
        setDispensary,
        appointmentDate,
        setAppointmentDate,
        dispensaryPrechecks,
      }}
    >
      {children}
    </DispensaryContext.Provider>
  );
};
export const Dispensary = () => {
  return (
    <DisepensaryProvider>
      <DispensaryContent />
    </DisepensaryProvider>
  );
};

export const DispensaryContent = () => {
  const { dispensary } = useContext(DispensaryContext);
  return (
    <>
      <div className="dispensary-header">
        <Link
          to={"/search-dispensaries"}
          className="back-home-dispensary link-unstyled"
        >
          <i className="pi pi-angle-left" />
          Back
        </Link>
      </div>
      <div className="dispensary-container">
        <div className="dispensary-body-container">
          <img
            className="dispensary-img"
            src={dispensary?.imgUrl}
            alt={`${dispensary?.name}-dispensary`}
          />
          <div className="dispensary-info-container">
            <div className="dispensary-info">
              <DispensaryName
                name={dispensary?.name}
                isPartner={dispensary?.isPartner}
              />
              <DispensaryRating rating={dispensary?.rating} />
              <DispensaryAddress
                address={dispensary?.location}
                isoCode={dispensary?.state?.isoCode}
                city={dispensary?.city}
              />
              <DispensaryVisitStore storeUrl={dispensary?.storeUrl} />
            </div>
            <a
              href={dispensary?.urlMap}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button className="primary">Directions</Button>
            </a>
          </div>
          <Appointments dispensary={dispensary} />
        </div>
      </div>
    </>
  );
};

const Appointments = ({ dispensary }) => {
  return (
    <div className="appointments">
      <div className="appointments-header">
        <p>Appointments</p>
        <AppointmentsDivider />
      </div>
      {dispensary?.isPartner && (
        <div>
          <AppointmentDatePicker />
          <p className="select-budtender">Select your budtender</p>
          <BudtendersList budtenders={dispensary?.budtenders} />
        </div>
      )}
    </div>
  );
};

const AppointmentsDivider = () => {
  return (
    <div className="appointment-divider">
      <div className="line" />
    </div>
  );
};
