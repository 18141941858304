import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import { AuthService } from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";
import "./changePassword.css";
import { encryptedData } from "src/app/shared/helpers/encrypt";
import { createEventGA } from "src/app/shared/helpers/analytics";

export const ChangePassword = () => {
  const authService = new AuthService();
  const appType = AppType();
  const toast = useRef(null);

  const [dataForm, setDataForm] = useState({
    currentPassword: "",
    password: "",
    password2: "",
  });

  const onChangeDataForm = (field) => (e) => {
    const value = e.target.value;
    setDataForm({ ...dataForm, [field]: value });
  };

  const isFormValid = Object.values(dataForm).every((value) => value !== "");

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (dataForm.password !== dataForm.password2) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Passwords do not match. Please check.",
        life: 2500,
      });
      return;
    }

    const payload = {
      currentPassword: dataForm.currentPassword,
      newPassword: dataForm.password,
    };
    const dataEncrypt = encryptedData(payload);

    const [err] = await authService.changePassword(
      { data: dataEncrypt },
      appType
    );
    if (err) {
      toast.current.show({
        severity: "error",
        summary: "Password",
        detail: err?.response?.data?.message,
      });
      createEventGA(
        "Dispensary",
        "change_password_error_dispensary",
        "Change password error dispensary"
      );
      return;
    }
    setDataForm({
      currentPassword: "",
      password: "",
      password2: "",
    });

    createEventGA(
      "Dispensary",
      "change_password_success_dispensary",
      "Change password success dispensary"
    );
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Password updated successfully",
    });
  };

  return (
    <div>
      <Toast ref={toast}></Toast>

      <form className="thcp-dispensary-signup-form">
        <span className="p-input-icon-left">
          <Password
            value={dataForm.currentPassword}
            onChange={onChangeDataForm("currentPassword")}
            feedback={false}
            toggleMask
          />
          <span className="thcp-float-label">Current Password</span>
        </span>
        <span className="p-input-icon-left">
          <Password
            value={dataForm.password}
            onChange={onChangeDataForm("password")}
            feedback={false}
            toggleMask
          />
          <span className="thcp-float-label">New Password</span>
        </span>
        <span className="p-input-icon-left">
          <Password
            value={dataForm.password2}
            onChange={onChangeDataForm("password2")}
            feedback={false}
            toggleMask
          />
          <span className="thcp-float-label">Confirm Password</span>
        </span>
        <div
          className={`thcp-action-button ${isFormValid ? "" : "p-disabled"}`}
        >
          <Button
            onClick={handleUpdate}
            label="Save"
            className="thcp-form-button-submit"
          />
        </div>
      </form>
    </div>
  );
};
