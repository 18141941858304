import { Card } from "primereact/card";
import React from "react";
import { passport } from "src/app/constants/passport.constants";

export const CannabisTypeTemplate = ({ listType }) => {
  const style = {
    passportCard: {
      borderRadius: "12px",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      fontFamily: '"Poppins", sans-serif',
      textAlign: "center",
      //OTHER
      height: "100%",
      width: "100%",
    },
    titleCard: {
      color: "#36b37e",
      padding: "0.3rem",
      fontSize: "1.1rem",
      fontWeight: 700,
    },
    content: {
      textAlign: "center",
      padding: "0",
      display: "flex",
      marginBottom: "0.3rem",
    },
    itemTypes: {
      background: "rgba(135, 246, 153, 0.5)",
      borderRadius: "12px",
      color: "#696c72",
      height: "22px",
      fontSize: "14px",
      fontWeight: "600",
      padding: "0 1rem",
      textAlign: "center",
      margin: "auto",
    },
  };

  return (
    <td>
      <Card
        style={style.passportCard}
        title={<span style={style.titleCard}>{passport.TITLE5}</span>}
      >
        <div style={style.content}>
          {listType?.map((item, i) => (
            <div style={style.itemTypes} key={i}>
              {item}
            </div>
          ))}
        </div>
      </Card>
    </td>
  );
};
