import { DispensariesCarousel } from "../dispensaries-carousel/DispensariesCarousel";
import "./dispensaries-top.css";

export const DispensariesTop = ({ dispensaries }) => {
  const dispensariesByState =
    dispensaries.length > 0 &&
    dispensaries.reduce((acc, item) => {
      if (!acc[item.city]) {
        acc[item.city] = [];
      }
      acc[item.city].push(item);
      return acc;
    }, {});

  return (
    <div>
      {Object.keys(dispensariesByState).map((key) => {
        return (
          <DispensariesTopPerCity
            key={key}
            city={key}
            isoCode={dispensariesByState[key][0].state.isoCode}
            dispensaries={dispensariesByState[key]}
          />
        );
      })}
    </div>
  );
};

const DispensariesTopPerCity = ({ city, isoCode, dispensaries }) => {
  return (
    <div>
      <p className="title-top">
        Top dispensaries in{" "}
        <span>
          {city}, {isoCode}
        </span>
      </p>
      <p className="subtitle-top">Our top {city} picks</p>
      <DispensariesCarousel dispensaries={dispensaries} />
    </div>
  );
};
