import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "./userContext";
import { getCookie } from "src/app/shared/utils/cookies.utils";
import { COOKIES } from "src/app/constants/cookies.constants";
import { UserService } from "src/app/shared/services";
import { AppType } from "src/app/shared/hooks/AppType";

function mapUserData(userRes) {
  return {
    username: userRes.username,
    rol: userRes.rol,
    firstName: userRes.firstName,
    lastName: userRes.lastName,
    verifiedStatus: userRes.verifiedStatus,
    profileImg: userRes.profileImg,
    userId: userRes._id,
    dispensaryType: userRes.dispensaryType,
    prodReccDisp: userRes.prodReccDisp,
  };
}

function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const appType = AppType();

  useEffect(() => {
    async function getUser() {
      const token = getCookie(
        appType === "dispensary"
          ? COOKIES.ACCESS_TOKEN_DISP
          : COOKIES.ACCESS_TOKEN
      );

      const userService = new UserService();
      const [userErr, userRes] = await userService.getCurrentUser(appType);
      if (userErr) {
        setUser(null);
        return;
      }
      if (!user?.logged) {
        setUser({ token: token, logged: true, ...mapUserData(userRes) });
        const from =
          location.pathname ||
          (userRes.rol === "thcp_dispensary"
            ? "/dispensary/dashboard"
            : "/user/profile/cannabis-preferences");
        navigate(from, { replace: true });
      }
    }
    getUser();
  }, [setUser]);

  useEffect(() => {
    async function getUser() {
      const token = getCookie(
        appType === "dispensary"
          ? COOKIES.ACCESS_TOKEN_DISP
          : COOKIES.ACCESS_TOKEN
      );
      const userService = new UserService();
      const [userErr, userRes] = await userService.getCurrentUser(appType);
      if (userErr) {
        setUser(null);
        return;
      }
      if (!user?.logged) {
        setUser({ token: token, logged: true, ...mapUserData(userRes) });
      }
    }
    getUser();
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
