import React, { useRef, useState } from "react";
import DashboardHeader from "./components/header";
import DashboardSidebar from "./components/sidebar";

import "./dashboard.css";
import PrecheckHistory from "./components/dashboard-options/precheck-history";
import ProfileDialog from "./components/user-profile-dialog";
import UpcomingPrecheck from "./components/dashboard-options/upcoming-precheck";
import PrecheckQueue from "./components/dashboard-options/precheck-queue";
import { Toast } from "primereact/toast";
import Settings from "./components/dashboard-options/settings";
import ComplianceReport from "./components/dashboard-options/compliance-report";

export const DispensariesDashboard = () => {
  const [active, setActive] = useState(1);
  const [activeDialog, setActiveDialog] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [selectedClient, setSelectedClient] = useState(null);
  const toast = useRef(null);

  const handleOnHide = () => {
    setActiveDialog(false);
    setSelectedProfile({});
  };

  return (
    <div className="container-dashboard">
      <Toast ref={toast} />
      <DashboardHeader />
      <div className="thcp-dashboard-content-container">
        <DashboardSidebar active={active} setActive={setActive} />
        {active === 1 && (
          <UpcomingPrecheck
            setActiveDialog={setActiveDialog}
            setSelectedProfile={setSelectedProfile}
            toast={toast}
          />
        )}
        {active === 2 && (
          <PrecheckQueue
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
          />
        )}
        {active === 3 && (
          <PrecheckHistory
            setActiveDialog={setActiveDialog}
            setSelectedProfile={setSelectedProfile}
          />
        )}
        {active === 4 && <ComplianceReport />}
        {active === 5 && <Settings />}
      </div>
      {activeDialog && (
        <ProfileDialog
          handleOnHide={handleOnHide}
          activeDialog={activeDialog}
          selectedprofile={selectedProfile}
        />
      )}
    </div>
  );
};
