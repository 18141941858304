// import { Card } from "primereact/card";
import React, { useEffect, useState } from "react";
import "./enjoyingCannabis.css";
import { Checkbox } from "primereact/checkbox";

export const EnjoyingCannabis = ({ data, select, handleSelect }) => {
  const [options, setOptions] = useState([]);

  const handleClickChoose = (id) => {
    handleSelect(data._id, id, true, 2);
  };

  const handleSelectAllForGroup = (index) => {
    const filteredIdFalse = options[index].items
      .filter((item) => !item.selected)
      .map((item) => item.id);
    const ids =
      filteredIdFalse.length === 1 ? filteredIdFalse[0] : filteredIdFalse;
    handleSelect(data._id, ids, true, 3);
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setOptions(data?.options);
      select(Object.keys(data?.optionsSelected).length > 0);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="container-preferences-enjoying">
      <p className="title-step">{data?.title}</p>
      <p className="subtitle-step">({data?.subtitle})</p>
      {options?.map((e, index) => (
        <div key={index} className="container-items">
          <div className="container-item-header">
            <p className="title-item">{e.name}</p>
            <div
              className={`select-all ${
                options[index].items.every((item) => item.selected)
                  ? "selected-all"
                  : ""
              }`}
              onClick={() =>
                !options[index].items.every((item) => item.selected) &&
                handleSelectAllForGroup(index)
              }
            >
              <p>SELECT ALL</p>
              <img
                src={`/icons/v2/steps/${
                  options[index].items.every((item) => item.selected)
                    ? "icon-check-back-white.png"
                    : "icon-check-back-green.png"
                }`}
                alt="icons-checks"
              />
            </div>
          </div>
          <div className="options">
            {e.items.map((item, index) => (
              <div
                className={`step4 ${item.selected ? "selected4" : ""}`}
                key={index}
                onClick={() => handleClickChoose(item?.id)}
              >
                <p>{item?.name}</p>
                <Checkbox checked={item.selected} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
