import React from 'react';

import './header.css';

const SignupHeader = () => {
  return (
    <div className='thcp-signup-header-container'>
      <img src='/imgs/logo-white.png' alt='thcp-logo' />
      <h1>Get Registered</h1>
    </div>
  );
};

export default SignupHeader;
