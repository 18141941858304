import React from "react";
import useAuth from "src/app/shared/hooks/useAuth";
import "./header.css";
import { passport } from "src/app/constants/passport.constants";

export const Header = ({ level, data = {} }) => {

  const { user } = useAuth();

  return (
    <div className="thc-profile-passport-header-container">
      <div className="thc-profile-passport-header-content">
        <div className="thcp-profile-passport-header_img">
          <img
            className="thcp-profile-passport-image"
            src={
              data?.img
                ? data?.img || "/imgs/default-user-pic.png"
                : user.profileImg?.url || "/imgs/default-user-pic.png"
            }
            alt="user_img"
          />
        </div>
        <div className="thcp-profile-passport-info_user">
          <h2>
            {data?.name ? data?.name : user?.firstName}{" "}
            {data?.lastName
              ? data?.lastName.split("")[0]
              : user.lastName.split("")[0]}
            .
          </h2>
          <h3>{passport.TITLE2}</h3>
          <div className="header-type">{level}</div>
        </div>
        <div className="thcp-profile-passport-logo">
          <div className="thc-profile-passport-header-title">
            <h2>{passport.TITLE1}</h2>
          </div>
          <div className="container-logo">
            <img
              src="/imgs/logo.png"
              alt="THCP logo"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
