import React, { useEffect, useRef, useState } from "react";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";

import useAuth from "src/app/shared/hooks/useAuth";
import { cleanCookie } from "src/app/shared/utils/cookies.utils";
import { COOKIES } from "src/app/constants/cookies.constants";
import { CannabisDispensariesService } from "src/app/shared/services";

import "./header.css";
import { AppType } from "src/app/shared/hooks/AppType";
import { createEventGA } from "src/app/shared/helpers/analytics";

const DashboardHeader = () => {
  const { user, setUser } = useAuth();
  const appType = AppType();
  const toast = useRef(null);
  const [logo, setLogo] = useState(null);

  const items = [
    {
      label: "Sign-out",
      icon: "pi pi-user",
      command: () => {
        localStorage.clear();
        setUser(null);
        createEventGA("Dispensary", "logout_dispensary", "Logout dispensary");
        cleanCookie(COOKIES.ACCESS_TOKEN_DISP);
      },
    },
  ];

  useEffect(() => {
    const dispensaryService = new CannabisDispensariesService();
    dispensaryService
      .getInfo(user.userId, appType)
      .then(([_, data]) => data.logo && setLogo(data.logo))
      .catch((err) => setLogo(null));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <header className="thcp-header-container">
      <div className="thcp-header-left">
        <img src="/imgs/logo.png" alt="thcp-logo" />
      </div>
      <div className="thcp-header-right">
        {logo && (
          <img
            src={logo}
            alt="dispensary_logo"
            style={{ maxWidth: "100px", maxHeight: "60px" }}
          />
        )}
        {/* <span>
          <i className="pi pi-bell"></i>
        </span>
        <span>
          <i className="pi pi-envelope"></i>
        </span> */}
        <div className="thcp-header-button">
          <Toast ref={toast}></Toast>
          <SplitButton label={user.username} model={items}>
            <img src="icons/default-user-icon.png" alt="user-icon" />
          </SplitButton>
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
