import React from 'react'
import SignupHeader from './components/header'
import SignupForm from './components/signup-form'

export const DispensariesSignUp = () => {
  return (
    <>
      <SignupHeader />
      <SignupForm />
    </>
  )
}
