import authClient from "../utils/auth-cookie-client.utils";
import client from "../utils/base-client.utils";

export class AuthService {
  async resetPasswordUser(payload) {
    try {
      const response = await client.post("/auth/reset-password", payload);
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async changePassword(payload, appType) {
    try {
      const response = await authClient(appType).post(
        "/auth/change-password",
        payload
      );
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async dispensarySignUp(payload) {
    try {
      const response = await client.post("/auth/dispensary/signup", payload);
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async dispensarySignIn(payload) {
    try {
      const response = await client.post("/auth/dispensary/signin", payload);
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
}
