import { decryptData, encryptedData } from "../helpers/encrypt";
import authClient from "../utils/auth-cookie-client.utils";

export class PrecheckService {
  async register(payload, appType) {
    try {
      const response = await authClient(appType).post("/precheck", payload);
      return [null, response.data];
    } catch (error) {
      console.error("register error::", error);
      return [error];
    }
  }

  async get(payload, appType) {
    try {
      const { date } = payload;
      let queryParamsString = "";
      if (date) {
        queryParamsString = `?date=${date}`;
      }
      const { data } = await authClient(appType).get(
        `/precheck${queryParamsString}`
      );

      return [null, data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
  async getByDispensary(payload, appType) {
    try {
      const { dispensaryId, date } = payload;
      let queryParamsString = "";
      if (date) {
        queryParamsString = `?date=${date}`;
      }
      const { data } = await authClient(appType).get(
        `/precheck/dispensary/${dispensaryId}${queryParamsString}`
      );

      return [null, data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
  async getUpcoming(payload) {
    try {
      const { data } = await authClient(payload).get(
        "/precheck/upcoming",
        payload
      );
      const dataDecrypt = decryptData(data?.payload);
      return [null, this.parse(dataDecrypt)];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async getPrevious(payload) {
    try {
      const { data } = await authClient(payload).get("/precheck/previous");
      const dataDecrypt = decryptData(data?.payload);
      return [null, this.parse(dataDecrypt)];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async getQueue(payload) {
    try {
      const status = "check-in";
      const { data } = await authClient(payload).get(
        `/precheck/upcoming?status=${status}`,
        payload
      );
      const dataDecrypt = decryptData(data?.payload);
      return [null, this.parse(dataDecrypt)];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async update(payload, appType) {
    try {
      const response = await authClient(appType).put("/precheck", payload);
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }
  async updateStatus(status = "prechecked", precheckId, appType) {
    try {
      const payload = {
        status,
        precheckId,
      };
      const dataEncrypt = encryptedData(payload);
      const response = await authClient(appType).put("/precheck/status", {
        data: dataEncrypt,
      });
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async getTotalPrecheckReport(appType) {
    try {
      const response = await authClient(appType).get("/precheck/report");

      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async sendReminderToClient(payload, appType) {
    try {
      const response = await authClient(appType).post(
        "/precheck/sms-reminder",
        payload
      );
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async updatePrecheck(payload, appType) {
    try {
      const response = await authClient(appType).put("/precheck", payload);
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async updatePrecheckNotes(payload, appType) {
    try {
      const response = await authClient(appType).put(
        "/precheck/notes",
        payload
      );
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async getUserPrechecks(username, appType) {
    try {
      const { data } = await authClient(appType).get(
        "/precheck/user/" + username
      );
      const dataDecrypt = decryptData(data?.payload);
      return [null, dataDecrypt];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  async getLastUserPrecheck(username, appType) {
    try {
      const { data } = await authClient(appType).get(
        `/precheck/user/${username}/last`
      );

      const dataDecrypt = data?.payload ? decryptData(data?.payload) : data;
      return [null, dataDecrypt];
    } catch (error) {
      // console.error("search error::", error);
      return [error];
    }
  }

  async cancelPrecheckByUser(precheckId, appType) {
    try {
      const response = await authClient(appType).put(
        `/precheck/user/cancel/${precheckId}`
      );
      return [null, response.data];
    } catch (error) {
      console.error("search error::", error);
      return [error];
    }
  }

  parse(data) {
    return data.map((d) => ({
      ...d,
      fullName: `${d.user.name} ${d.user.lastName}`,
    }));
  }
}
